import { ReactNode, forwardRef, useEffect, useState } from 'react'

import { Icon, IconName, Tooltip } from '@joor/design-system'
import '@joor/design-system/dist/designTokens/variables.css'
import styled, { FlattenSimpleInterpolation, css } from 'styled-components'

import { useScreenSize } from 'hooks/screenHooks'

const StyledIcon = styled(Icon)`
  cursor: pointer;
`

type StyledIconWithTransitionProps = {
  $isOpen: boolean
  $disabled: boolean
}

const StyledIconWithTransition = styled(
  StyledIcon,
)<StyledIconWithTransitionProps>`
  transition: transform 0.3s ease;

  ${({ $isOpen, $disabled }): FlattenSimpleInterpolation | undefined => {
    if ($isOpen) {
      return css`
        transform: rotate(90deg);
      `
    }
    if ($disabled) {
      return css`
        --main-color: var(--color-primary-200) !important;
        cursor: not-allowed;
      `
    }
  }}
`

const SidebarContainer = styled.div`
  height: 50px;
  width: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-secondary-200);
  position: relative;
  box-shadow: 5px 0 5px -5px rgba(0, 0, 0, 0.2);
`

const Separator = styled.div`
  height: 1px;
  width: 24px;
  background-color: #cdd4da;
  position: absolute;
  left: 16px;
`

const TooltipContainer = styled.div`
  span > div:nth-child(2) {
    width: max-content;
  }
`

type ContainerProps = {
  $wide: boolean
}

const ChildrenContainer = styled.div<ContainerProps>`
  display: flex;
  min-width: 56px;
  height: 100vh;
  background-color: var(--color-secondary-200);
  position: absolute;
  z-index: 6;
  top: 50px;
  left: 0;
  box-shadow: 5px 0 5px -5px rgba(0, 0, 0, 0.2);

  > div:nth-child(2) {
    width: 56px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    > div {
      vertical-align: middle;
      margin-top: 12px;
    }
  }
  > div:last-child {
    height: 100%;
    display: none;
    background-color: var(--color-neutral-000-full);
    z-index: 6;
    margin-top: -50px;
  }
  ${({ $wide }): FlattenSimpleInterpolation | undefined => {
    if ($wide) {
      return css`
        > div:last-child {
          display: inherit;
          width: 326px;
          box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
            0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
        }
      `
    }
  }}
`

export type SidebarElement = {
  id: number
  iconName: IconName
  tooltip: string
  content: ReactNode
}

type SidebarProps = {
  elements: SidebarElement[]
  className?: string
  onSidebarElementClick?: () => void
  selectedElement: number
  setSelectedElement: (value: number) => void
  isOpen?: boolean
  setIsOpen?: (isOpen: boolean) => void
}

const Sidebar = forwardRef<HTMLInputElement, SidebarProps>(
  (
    {
      elements,
      className,
      onSidebarElementClick,
      setSelectedElement,
      selectedElement,
      isOpen: opened = false,
      setIsOpen: setOpened,
    }: SidebarProps,
    ref,
  ) => {
    const [isOpen, setIsOpen] = useState(opened)
    const isUnderReferenceMedium = useScreenSize(767)

    const isBeingControlled = opened !== undefined && setOpened !== undefined

    const currentContent = elements.find((e) => e.id === selectedElement)
      ?.content

    useEffect(() => {
      if (isOpen && isUnderReferenceMedium) {
        isBeingControlled ? setOpened(false) : setIsOpen(false)
      }
    }, [isUnderReferenceMedium, isOpen, isBeingControlled, setOpened])
    return (
      <SidebarContainer ref={ref} className={className}>
        <TooltipContainer>
          <Tooltip
            defaultPlacement="right"
            content={`${
              isUnderReferenceMedium
                ? 'Editing options are not available at this browser size. Please expand to edit.'
                : (isBeingControlled ? opened : isOpen)
                ? 'Hide side navigation'
                : 'Show side navigation'
            }`}
          >
            <StyledIconWithTransition
              iconName="menu"
              $isOpen={isBeingControlled ? opened : isOpen}
              $disabled={isUnderReferenceMedium}
              {...(!isUnderReferenceMedium && {
                onClick: (): void => {
                  const openedStatus = isBeingControlled ? opened : isOpen
                  isBeingControlled
                    ? setOpened(!openedStatus)
                    : setIsOpen(!openedStatus)
                  setSelectedElement(-1)
                },
              })}
            />
          </Tooltip>
        </TooltipContainer>

        {(isBeingControlled ? opened : isOpen) && (
          <ChildrenContainer $wide={selectedElement !== -1}>
            <Separator />
            <div>
              {elements.map((sidebarElement, i) => (
                <TooltipContainer
                  key={i}
                  data-testid={`storefront_sidebar_${sidebarElement.iconName}`}
                >
                  <Tooltip
                    defaultPlacement="right"
                    content={<div>{sidebarElement.tooltip}</div>}
                  >
                    <StyledIcon
                      iconName={sidebarElement.iconName}
                      {...(selectedElement === sidebarElement.id && {
                        variant: 'ACTIVE',
                      })}
                      onClick={(): void => {
                        onSidebarElementClick?.()
                        setSelectedElement(
                          selectedElement === sidebarElement.id
                            ? -1
                            : sidebarElement.id,
                        )
                      }}
                    />
                  </Tooltip>
                </TooltipContainer>
              ))}
            </div>
            <div>{currentContent}</div>
          </ChildrenContainer>
        )}
      </SidebarContainer>
    )
  },
)

export default Sidebar
