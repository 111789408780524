export const messages = {
  forBrandsLink: 'JOOR for Brands',
  forRetailersLink: 'JOOR for Retailers',
  aboutLink: 'About JOOR',
  blogLink: 'Blog',
  passportLink: 'Passport',
  requestDemoLink: 'Request a Brand Demo',
  retailerSignUpLink: 'Free Retailer Sign Up',
  logInButton: 'Log In',
}
