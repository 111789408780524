import { ObservableQuery, useQuery } from '@apollo/client'
import { uniqBy } from 'lodash'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import {
  LGProfileCompleteness,
  RetailerProfilePageQueryDocument,
} from '__generated__/atlas-types'

import { fromGlobalId, toGlobalId } from 'utils/transformations/graphql'

import { atlasClient } from 'graphql/client'
import { atlas } from 'graphql/tags'
import { isAccountTypeBrand } from 'store/currentUser/selectors'

import { RetailerProfile } from './retailerProfilePage.types'

atlas`#graphql
  query RetailerProfilePageQuery ($retailerId: ID!, $isBrandAccount: Boolean!) {
    retailerProfile (id: $retailerId) {
      id
      description
      name
      displayName
      profileSnoozeCounter
      autoConnect
      connectionStatus
      profileCompleteness
      logo {
        id
        url
      }
      websiteUrl
      socialMediaAccounts {
        type
        url
      }
      profileImages {
        id
        upload {
          id
          name
          url
          mediaType
        }
      }
      primaryLocation {
        id
        name
        address1
        address2
        city
        state
        country
        zip
        phone
        type
        wholesaleRange {
          min
          max
        }
        clienteleInfo {
          age
          male
          female
          descriptions {
            id
            name
          }
          interests {
            id
            name
          }
        }
        brandsCarried {
          id
          name
          accountId
        }
        categories {
          id
          name
        }
        yearEstablished
      }
      locations {
        id
        name
        address1
        address2
        city
        state
        country
        zip
        phone
        type
        wholesaleRange {
          min
          max
        }
        clienteleInfo {
          age
          male
          female
          descriptions {
            id
            name
          }
          interests {
            id
            name
          }
        }
        brandsCarried {
          id
          name
          accountId
        }
        categories {
          id
          name
        }
        yearEstablished
      }
      buyer {
        name
        isBuyer
        isOwner
      }
      people {
        id
        name
        jobTitle
        profilePictureUrl
        phone
        submissions  @include(if: $isBrandAccount) {
          id
          createdAt
          messageId
          senderAccount {
            accountName
            accountUserName
          }
          }
        }
        accountSubmission  @include(if: $isBrandAccount){
          id
          messageId
          createdAt
          senderAccount {
            accountName
            accountUserName
          }
        }
        awards{
          id
          description
          awardBadgeUrl
        }
      }
    }
`

type UseRetailerDataHookReturn = {
  retailer: RetailerProfile
  loading: boolean
  refetchProfileData: ObservableQuery['refetch']
}

export const useRetailerData = (): UseRetailerDataHookReturn => {
  const isBrandAccount = useSelector<object, boolean>(isAccountTypeBrand)
  const { accountId } = useParams<{ accountId: string }>()
  const retailerId = toGlobalId('Retailer', accountId)
  const { data, loading, refetch: refetchProfileData } = useQuery(
    RetailerProfilePageQueryDocument,
    {
      client: atlasClient,
      variables: {
        retailerId: retailerId || '',
        isBrandAccount,
      },
      skip: !retailerId,
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    },
  )
  const retailerProfile = data?.retailerProfile

  const primaryLocation = retailerProfile?.primaryLocation
    ? {
        ...retailerProfile.primaryLocation,
        brandsCarried: uniqBy(
          retailerProfile?.primaryLocation?.brandsCarried
            ?.filter(
              (brandCarried) =>
                fromGlobalId(brandCarried.accountId)?.id !== null,
            )
            ?.map((carriedBrand) => ({
              id: carriedBrand.accountId ?? '',
              name: carriedBrand.name,
            })),
          'id',
        ),
      }
    : null

  const formattedLocations = retailerProfile?.locations?.map((location) => ({
    ...location,
    brandsCarried: uniqBy(
      location?.brandsCarried
        ?.filter(
          (brandCarried) => fromGlobalId(brandCarried.accountId)?.id !== null,
        )
        ?.map((carriedBrand) => ({
          id: carriedBrand.accountId ?? '',
          name: carriedBrand.name,
        })),
      'id',
    ),
  }))

  return {
    retailer: {
      ...retailerProfile,
      profileCompleteness:
        retailerProfile?.profileCompleteness || LGProfileCompleteness.EMPTY,
      id: retailerProfile?.id || '',
      displayName: retailerProfile?.displayName || '',
      name: retailerProfile?.name,
      primaryLocation,
      locations: formattedLocations ?? [],
      people: retailerProfile?.people ?? [],
    },
    loading,
    refetchProfileData,
  }
}
