import { useMutation } from '@apollo/client'
import { useSelector } from 'react-redux'

import { toGlobalId } from 'utils/transformations/graphql'

import { atlasClient } from 'graphql/client'
import { getUserId } from 'store/currentUser/selectors'

import { SET_DISPLAY_LANGUAGE_FOR_USER } from './localizeDropdown.queries'

export const useUpdateSelectedLanguage = () => {
  const userId = useSelector((state) => getUserId(state))

  const [setDisplayLanguageForUser] = useMutation(
    SET_DISPLAY_LANGUAGE_FOR_USER,
    {
      client: atlasClient,
    },
  )

  const updateSelectedLanguage = (code: string) => {
    if (userId) {
      setDisplayLanguageForUser({
        variables: {
          input: {
            id: toGlobalId('User', userId),
            languageCode: code,
          },
        },
      })
    }
  }
  return { updateSelectedLanguage }
}
