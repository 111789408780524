import compose from 'lodash/flowRight'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { isLiteRetailer, isProRetailer } from 'store/currentUser/selectors'

import CreateOrderTemplateModal from 'modals/OrderList/CreateOrderTemplateModal'
import ExcelOrdersModal from 'modals/OrderList/ExcelOrdersModal'
import ExcelSummaryModal from 'modals/OrderList/ExcelSummaryModal'
import { ORDER_LIST } from 'routes/paths'

import MenuLinkWrapper from '../MenuLinkWrapper/MenuLinkWrapper'
import NavbarDropdown from '../NavbarDropdown/NavbarDropdown'
import { NavbarOrdersMenu } from '../OrdersDropdown/NavbarOrdersOrdersMenu'
import Trigger from '../Trigger/Trigger'
import withModal from 'containers/ModalHandler/ModalHandler.component'
import messages from 'features/Navbar/NavbarRevamp/constants/navbarRevamp.messages'
import { OrdersDropdownIds } from 'features/Navbar/NavbarRevamp/navbarRevamp.ids'
import {
  INavbarOrdersDropdown,
  IOrdersDropdownSelectors,
} from 'features/Navbar/NavbarRevamp/navbarRevamp.types'

const NavbarOrdersDropdownContainer = styled.div`
  display: flex;
`

const NavbarOrdersDropdown = ({ openDynamicModal }: INavbarOrdersDropdown) => {
  const { isLiteRetailerAccount, isProRetailerAccount } = useSelector<
    object,
    IOrdersDropdownSelectors
  >((state) => ({
    isLiteRetailerAccount: isLiteRetailer(state),
    isProRetailerAccount: isProRetailer(state),
  }))

  const openExcelSummaryModal = () => {
    openDynamicModal({
      component: ExcelSummaryModal,
      openExcelOrdersModal: openExcelOrdersModal,
    })
  }

  const openCreateOrderTemplateModal = () => {
    openDynamicModal({
      component: CreateOrderTemplateModal,
      openExcelOrdersModal: openExcelOrdersModal,
    })
  }

  const openExcelOrdersModal = () => {
    openDynamicModal({
      component: ExcelOrdersModal,
      openCreateOrderTemplateModal: openCreateOrderTemplateModal,
      openExcelSummaryModal: openExcelSummaryModal,
    })
  }

  if (isProRetailerAccount) {
    return null
  }

  if (isLiteRetailerAccount) {
    return (
      <MenuLinkWrapper
        to={ORDER_LIST}
        id={OrdersDropdownIds.OrdersOptionOrders}
        data-testid={OrdersDropdownIds.OrdersOptionOrders}
      >
        {messages.manageOrders}
      </MenuLinkWrapper>
    )
  }

  return (
    <NavbarOrdersDropdownContainer>
      <NavbarDropdown
        trigger={
          <Trigger
            label={messages.orders}
            id={OrdersDropdownIds.OrdersTrigger}
          />
        }
      >
        {<NavbarOrdersMenu openDynamicModal={openDynamicModal} />}
      </NavbarDropdown>
    </NavbarOrdersDropdownContainer>
  )
}

export default compose(withModal)(NavbarOrdersDropdown)
