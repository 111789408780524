import { Icon } from '@joor/design-system'
import { useFlags } from '@joor/launchdarkly-react-client-sdk'
import { HELP } from 'externalUrls'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { clearStorage } from 'utils/storage'

import {
  allowManagePayments as allowManagePaymentsSelector,
  allowManagePermissions as allowManagePermissionsSelector,
  createIsIntegratedWithSelector,
  getUserId,
  isAccountTypeBrand,
  isAccountTypeRetailer,
  isEditInventorySettingsAllowed,
  isEditStylesRestricted,
  isLiteBrand,
  userCanTransact,
  userCanViewRetailerSettings,
  userIsAdmin,
  userIsLoggedInAsAdmin,
} from 'store/currentUser/selectors'

import {
  ADMIN_LOGOUT,
  LOGOUT,
  MY_SETTINGS,
  SSO_SETTINGS,
  THIRD_PARTY_SETTINGS,
} from 'routes/paths'

import {
  ISettingsDropdown,
  ISettingsDropdownSelectors,
} from '../../navbarRevamp.types'
import DropdownMenuGroup from '../DropdownMenuGroup/DropdownMenuGroup'
import DropdownMenuTextLink from '../DropdownMenuTextLink/DropdownMenuTextLink'
import NavbarDropdown from '../NavbarDropdown/NavbarDropdown'
import Trigger from '../Trigger/Trigger'
import AdminSettings from './components/AdminSettings'
import BrandSettings from './components/BrandSettings'
import IntegrationsSettings from './components/IntegrationsSettings'
import RetailerSettings from './components/RetailerSettings'
import messages from 'features/Navbar/NavbarRevamp/constants/navbarRevamp.messages'
import { SettingsDropdownIds } from 'features/Navbar/NavbarRevamp/navbarRevamp.ids'

const NavbarSettingsDropdownContainer = styled.div<ISettingsDropdown>`
  display: flex;

  .trigger > div {
    padding: 0 2px;

    > div {
      height: 24px;
    }
  }

  @media only screen and (max-width: 1024px) {
    display: ${({ isInsideDropdown }) => (isInsideDropdown ? 'flex' : 'none')};
  }
`

const SettingsMenuLabel = styled.span`
  margin-left: 4px;
  color: var(--color-secondary-800);
  font-size: var(--size-font-small);
  font-weight: var(--font-weight-medium);
  line-height: 16px;
  padding: 6px 0;
  margin-right: auto;
`

const SettingsIcon = styled.div<ISettingsDropdown>`
  position: relative;
  cursor: pointer;

  svg * {
    fill: ${({ isInsideDropdown }) =>
      isInsideDropdown ? 'var(--color-primary-600)' : 'white'};
  }

  @media only screen and (max-width: 1024px) {
    & > span {
      width: 20px;
      height: 20px;
      padding: 2px 0;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
`

const CustomSettingsTrigger = ({ isInsideDropdown }: ISettingsDropdown) => (
  <Trigger
    label={
      <>
        <SettingsIcon isInsideDropdown={isInsideDropdown}>
          <Icon iconName="settings" variant="DEFAULT" type="white" plain />
        </SettingsIcon>
        <>
          {isInsideDropdown && (
            <SettingsMenuLabel>{messages.settings}</SettingsMenuLabel>
          )}
        </>
      </>
    }
    id={SettingsDropdownIds.SettingsTrigger}
    isInsideDropdown={isInsideDropdown}
  />
)

const NavbarSettingsDropdown = ({ isInsideDropdown }: ISettingsDropdown) => {
  const {
    isLoggedInAsAdmin,
    isAdmin,
    isIntegratedWithKellwood,
    isIntegratedWithRLM,
    restrictEditStyles,
    allowManagePermissions,
    userId,
    allowEditInventorySettings,
    allowManagePayments,
    canTransact,
    isBrandAccount,
    canViewRetailerSettings,
    isRetailerAccount,
    isLiteBrandAccount,
  } = useSelector<object, ISettingsDropdownSelectors>((state) => ({
    isLoggedInAsAdmin: userIsLoggedInAsAdmin(state),
    isAdmin: userIsAdmin(state),
    isIntegratedWithKellwood: createIsIntegratedWithSelector('Kellwood')(state),
    isIntegratedWithRLM: createIsIntegratedWithSelector('RLM')(state),
    restrictEditStyles: isEditStylesRestricted(state),
    allowManagePermissions: allowManagePermissionsSelector(state),
    userId: getUserId(state),
    allowEditInventorySettings: isEditInventorySettingsAllowed(state),
    allowManagePayments: allowManagePaymentsSelector(state),
    canTransact: userCanTransact(state),
    isBrandAccount: isAccountTypeBrand(state),
    canViewRetailerSettings: userCanViewRetailerSettings(state),
    isRetailerAccount: isAccountTypeRetailer(state),
    isLiteBrandAccount: isLiteBrand(state),
  }))
  const {
    vntana,
    ssoManagement,
    brandPaymentsSettings,
    retailIntegrationSettings,
    selfServePermissioning,
    newPermissionsPage,
  } = useFlags()

  const canViewPermissions =
    isBrandAccount && !isLiteBrandAccount && selfServePermissioning
  const canViewPermissionsAsRetailer = !isBrandAccount && selfServePermissioning

  const canManagePermissions =
    (canViewPermissions || canViewPermissionsAsRetailer) &&
    allowManagePermissions &&
    newPermissionsPage

  return (
    <NavbarSettingsDropdownContainer isInsideDropdown={isInsideDropdown}>
      <NavbarDropdown
        testId={SettingsDropdownIds.SettingsTrigger}
        trigger={<CustomSettingsTrigger isInsideDropdown={isInsideDropdown} />}
        rightPosition
        isInsideDropdown={isInsideDropdown}
      >
        <DropdownMenuGroup>
          <DropdownMenuTextLink
            to={MY_SETTINGS}
            message={messages.mySettings}
            id={SettingsDropdownIds.SettingsOptionMySettings}
          />
          {vntana && isBrandAccount && (
            <DropdownMenuTextLink
              to={THIRD_PARTY_SETTINGS}
              message={messages.thirdPartySettings}
              id={SettingsDropdownIds.SettingsOptionThirdParty}
            />
          )}
          <IntegrationsSettings
            isRetailerAccount={isRetailerAccount}
            retailIntegrationSettings={retailIntegrationSettings}
            isBrandAccount={isBrandAccount}
          />
          {ssoManagement && (
            <DropdownMenuTextLink
              to={SSO_SETTINGS}
              message={messages.ssoSettings}
              id={SettingsDropdownIds.SettingsOptionSSO}
            />
          )}
          <RetailerSettings
            isRetailerAccount={isRetailerAccount}
            canViewRetailerSettings={canViewRetailerSettings}
          />
          <BrandSettings
            isBrandAccount={isBrandAccount}
            isLiteBrandAccount={isLiteBrandAccount}
            restrictEditStyles={restrictEditStyles}
            allowManagePayments={allowManagePayments}
            brandPaymentsSettings={brandPaymentsSettings}
            canTransact={canTransact}
            canManagePermissions={canManagePermissions}
            allowEditInventorySettings={allowEditInventorySettings}
          />
          <AdminSettings
            isAdmin={isAdmin}
            isLiteBrandAccount={isLiteBrandAccount}
            isIntegratedWithKellwood={isIntegratedWithKellwood}
            isIntegratedWithRLM={isIntegratedWithRLM}
            isBrandAccount={isBrandAccount}
          />
          <DropdownMenuTextLink
            to={{ pathname: HELP }}
            message={messages.helpCenter}
            target="_blank"
            id={SettingsDropdownIds.SettingsOptionHelp}
          />
          {isLoggedInAsAdmin && (
            <DropdownMenuTextLink
              to={ADMIN_LOGOUT}
              message={messages.switchToAdmin}
              onClick={() => clearStorage(`${userId}`)}
              id={SettingsDropdownIds.SettingsOptionSwitchTo}
            />
          )}
          <DropdownMenuTextLink
            to={LOGOUT}
            message={messages.logout}
            onClick={() => clearStorage(`${userId}`)}
            id={SettingsDropdownIds.SettingsOptionLogout}
          />
        </DropdownMenuGroup>
      </NavbarDropdown>
    </NavbarSettingsDropdownContainer>
  )
}

export default NavbarSettingsDropdown
