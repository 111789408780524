import { FC } from 'react'

import { useFlags } from '@joor/launchdarkly-react-client-sdk'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { isAccountTypeRetailer, isLiteBrand } from 'store/currentUser/selectors'

import {
  CONNECTION_REQUESTS,
  CURRENT_CONNECTIONS,
  FIND_BOUTIQUES,
  FIND_NEW_RETAILERS,
  PENDING_CONNECTIONS,
} from 'routes/paths'

import DropdownMenuGroup from '../DropdownMenuGroup/DropdownMenuGroup'
import DropdownMenuTextLink from '../DropdownMenuTextLink/DropdownMenuTextLink'
import NavbarDropdown from '../NavbarDropdown/NavbarDropdown'
import Trigger from '../Trigger/Trigger'
import messages from 'features/Navbar/NavbarRevamp/constants/navbarRevamp.messages'
import { ConnectionsDropdownIds } from 'features/Navbar/NavbarRevamp/navbarRevamp.ids'
import { IConnectionsDropdownSelectors } from 'features/Navbar/NavbarRevamp/navbarRevamp.types'

const NavbarConnectionsDropdownContainer = styled.div`
  display: flex;
`
type ProBrandConnectionsProps = {
  findNewRetailersFlag: boolean
}

const ProBrandConnections: FC<ProBrandConnectionsProps> = ({
  findNewRetailersFlag,
}) => (
  <DropdownMenuGroup>
    <DropdownMenuTextLink
      to={CURRENT_CONNECTIONS}
      message={messages.manageConnections}
      id={ConnectionsDropdownIds.ConnectionOptionsManage}
      testId={ConnectionsDropdownIds.ConnectionOptionsManage}
    />
    <DropdownMenuTextLink
      to={CONNECTION_REQUESTS}
      message={messages.connectionsRequests}
      id={ConnectionsDropdownIds.ConnectionOptionsRequests}
      testId={ConnectionsDropdownIds.ConnectionOptionsRequests}
    />
    <DropdownMenuTextLink
      to={PENDING_CONNECTIONS}
      message={messages.pendingConnections}
      id={ConnectionsDropdownIds.ConnectionOptionsPending}
      testId={ConnectionsDropdownIds.ConnectionOptionsPending}
    />
    <DropdownMenuTextLink
      to={findNewRetailersFlag ? FIND_NEW_RETAILERS : FIND_BOUTIQUES}
      message={messages.findNewRetailers}
      id={ConnectionsDropdownIds.ConnectionOptionsFindRetailers}
      testId={ConnectionsDropdownIds.ConnectionOptionsFindRetailers}
    />
  </DropdownMenuGroup>
)

const LiteBrandConnections = () => (
  <DropdownMenuGroup>
    <DropdownMenuTextLink
      to={CURRENT_CONNECTIONS}
      message={messages.myConnections}
      id={ConnectionsDropdownIds.ConnectionOptionsManage}
      testId={ConnectionsDropdownIds.ConnectionOptionsManage}
    />
    <DropdownMenuTextLink
      to={CONNECTION_REQUESTS}
      message={messages.connectionsRequests}
      id={ConnectionsDropdownIds.ConnectionOptionsRequests}
      testId={ConnectionsDropdownIds.ConnectionOptionsRequests}
    />
  </DropdownMenuGroup>
)

const RetailerConnections = () => (
  <NavbarConnectionsDropdownContainer>
    <NavbarDropdown
      trigger={
        <Trigger
          label={messages.connections}
          id={ConnectionsDropdownIds.ConnectionTrigger}
        />
      }
    >
      <DropdownMenuGroup>
        <DropdownMenuTextLink
          to={CURRENT_CONNECTIONS}
          message={messages.manageConnections}
          id={ConnectionsDropdownIds.ConnectionOptionsManage}
          testId={ConnectionsDropdownIds.ConnectionOptionsManage}
        />
        <DropdownMenuTextLink
          to={PENDING_CONNECTIONS}
          message={messages.pendingConnections}
          id={ConnectionsDropdownIds.ConnectionOptionsRequests}
          testId={ConnectionsDropdownIds.ConnectionOptionsRequests}
        />
      </DropdownMenuGroup>
    </NavbarDropdown>
  </NavbarConnectionsDropdownContainer>
)

const NavbarConnectionsDropdown = () => {
  const { isRetailerAccount, isLiteBrandAccount } = useSelector<
    object,
    IConnectionsDropdownSelectors
  >((state) => ({
    isRetailerAccount: isAccountTypeRetailer(state),
    isLiteBrandAccount: isLiteBrand(state),
  }))
  const { findNewRetailersFlag } = useFlags()
  if (isRetailerAccount) {
    return <RetailerConnections />
  }

  return (
    <NavbarConnectionsDropdownContainer>
      <NavbarDropdown
        trigger={
          <Trigger
            label={messages.connections}
            id={ConnectionsDropdownIds.ConnectionTrigger}
          />
        }
      >
        {isLiteBrandAccount ? (
          <LiteBrandConnections />
        ) : (
          <ProBrandConnections findNewRetailersFlag={findNewRetailersFlag} />
        )}
      </NavbarDropdown>
    </NavbarConnectionsDropdownContainer>
  )
}

export default NavbarConnectionsDropdown
