import { Component } from 'react'

import classNames from 'classnames'
import PropTypes from 'prop-types'

import { Tooltip } from 'components/Core'

import styles from './HeaderAndValues.less'

const EMPTY_CELL_CONTENT = '—'
class HeaderAndValues extends Component {
  renderValues = (values) =>
    values.map((value, idx) => (
      // eslint-disable-next-line react/no-array-index-key
      <div
        key={idx}
        className={value ? 'detail-value' : `${styles.worthless} detail-value`}
      >
        {value || EMPTY_CELL_CONTENT}
      </div>
    ))

  render() {
    const {
      header,
      values,
      className,
      headerClassName,
      size,
      translateValues,
      displayOnlyHeader,
      enableHeaderTooltip,
      showContentPopup,
      id,
    } = this.props
    const classes = classNames([
      styles.headerDetailSection,
      className,
      [styles[size]],
    ])
    const headerClasses = classNames([styles.header, headerClassName])

    return (
      <div className={classes}>
        {enableHeaderTooltip ? (
          <Tooltip
            trigger={<div className={headerClasses}>{header}</div>}
            content={showContentPopup}
          />
        ) : (
          <div className={headerClasses}>{header}</div>
        )}
        {!displayOnlyHeader && (
          <span className={translateValues ? '' : 'notranslate'}>
            {this.renderValues(values, id)}
          </span>
        )}
      </div>
    )
  }
}

HeaderAndValues.propTypes = {
  header: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.node).isRequired,
  headerClassName: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.oneOf(['big', 'small']),
  translateValues: PropTypes.bool,
  displayOnlyHeader: PropTypes.bool,
  enableHeaderTooltip: PropTypes.bool,
  showContentPopup: PropTypes.string,
}

const NON_BREAKING_SPACE = '\u00a0'

HeaderAndValues.defaultProps = {
  className: '',
  headerClassName: '',
  header: NON_BREAKING_SPACE,
  size: 'small',
  translateValues: false,
  displayOnlyHeader: false,
  enableHeaderTooltip: false,
  showContentPopup: '',
}

export default HeaderAndValues
