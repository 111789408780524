import { Icon, SmallSecondaryBadge } from '@joor/design-system'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { formatNumber } from 'utils/formatters'

import { isAccountTypeBrand } from 'store/currentUser/selectors'

import { CONNECTION_REQUESTS } from 'routes/paths'

import { useNewConnectionsCount } from './connectionsMenuOption.hooks'
import messages from 'features/Navbar/NavbarRevamp/constants/navbarRevamp.messages'
import { ConnectionsDropdownIds } from 'features/Navbar/NavbarRevamp/navbarRevamp.ids'
import { IConnectionsMenu } from 'features/Navbar/NavbarRevamp/navbarRevamp.types'

const ConnectionMenuLink = styled(Link)`
  position: relative;
  border-bottom: none;
  padding: 0 8px;
  margin: 14px 0;
  margin-right: 4px;
  &:hover {
    border: 1px solid var(--color-primary-400);
    border-radius: 4px;
    margin: 13px -1px;
    margin-right: 3px;
  }

  @media only screen and (max-width: 1024px) {
    display: none;
    margin: 0;
    padding: 2px 0;

    svg * {
      fill: var(--color-primary-600);
    }

    & > span:first-child {
      width: 20px;
      height: 20px;
      padding: 4px 0;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    &:hover {
      margin: -1px;
    }
  }
`

const QuantityBadge = styled.span`
  position: absolute;
  top: -10px;
  left: 20px;
  z-index: 999;

  @media only screen and (max-width: 1024px) {
    top: -8px;
    left: 8px;
  }
`

const ConnectionsMenuLabel = styled.span`
  margin-left: 4px;
  color: var(--color-secondary-800);
  font-size: var(--size-font-small);
  font-weight: var(--font-weight-medium);
  line-height: 16px;
  padding: 6px 0;
  margin-right: auto;
`

const ConnectionMenuOption = ({ isInsideDropdown }: IConnectionsMenu) => {
  const { newConnectionsCount } = useNewConnectionsCount()
  const isBrandAccount = useSelector<object, boolean>(isAccountTypeBrand)

  if (isBrandAccount) {
    return null
  }

  return (
    <ConnectionMenuLink
      to={CONNECTION_REQUESTS}
      id={ConnectionsDropdownIds.ConnectionIconTrigger}
      data-testid={ConnectionsDropdownIds.ConnectionIconTrigger}
      aria-label={messages.connections}
    >
      <Icon iconName="group" variant="DEFAULT" type="white" plain />
      {newConnectionsCount > 0 && (
        <QuantityBadge>
          <SmallSecondaryBadge value={formatNumber(newConnectionsCount)} />
        </QuantityBadge>
      )}
      {isInsideDropdown && (
        <ConnectionsMenuLabel>{messages.connections}</ConnectionsMenuLabel>
      )}
    </ConnectionMenuLink>
  )
}

export default ConnectionMenuOption
