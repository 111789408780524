import { CLIENTELE_AGE } from 'features/LeadGen/RetailerProfile/RetailerProfileEditMode/components/LocationWidgetEditMode/LocationWidgetEditMode.hooks'
import { RetailerProfileLocation } from 'features/LeadGen/RetailerProfile/retailerProfilePage.types'

export const getFormattedLocation = (location: RetailerProfileLocation) => {
  const formattedAddress = [location.address1, location.address2]
    .filter((addressElement) => Boolean(addressElement))
    .join(', ')
  const formattedLocationInfo = [
    location.city,
    location.state,
    location.country,
  ]
    .filter((addressElement) => Boolean(addressElement))
    .join(', ')
  return { formattedAddress, formattedLocationInfo }
}

export const useClienteleInfo = (
  clienteleInfo?: RetailerProfileLocation['clienteleInfo'] | null,
) => {
  const hasBasicInfo =
    Boolean(clienteleInfo?.male) ||
    Boolean(clienteleInfo?.female) ||
    Boolean(clienteleInfo?.age)
  const hasInfo =
    hasBasicInfo ||
    Boolean(clienteleInfo?.descriptions?.length) ||
    Boolean(clienteleInfo?.interests?.length)
  return {
    hasBasicInfo,
    hasInfo,
  }
}

export const getBasicInfoFormatted = (
  clienteleMale?: boolean | null,
  clienteleFemale?: boolean | null,
  clienteleAge?: string | null,
) => {
  const clienteleAllAges = CLIENTELE_AGE[4].value
  const clienteleAllAgesName = 'All Ages'
  const clienteleAgeNumber = clienteleAge?.split(' (')?.[0]
  const carriesMaleClientele = clienteleMale ? 'Male' : undefined
  const carriesFealeClientele = clienteleFemale ? 'Female' : undefined
  const cleanClienteleAge =
    clienteleAge === clienteleAllAges
      ? clienteleAllAgesName
      : clienteleAgeNumber

  const basicInfoFormatted = [
    carriesMaleClientele,
    carriesFealeClientele,
    cleanClienteleAge,
  ]
    .filter((info) => Boolean(info))
    .join(', ')

  return basicInfoFormatted
}

export const getFormattedClienteleInfo = (
  clienteleInfo?: RetailerProfileLocation['clienteleInfo'] | null,
) => {
  const basicInfoFormatted = getBasicInfoFormatted(
    clienteleInfo?.male,
    clienteleInfo?.female,
    clienteleInfo?.age,
  )
  const clienteleDescriptionFormatted = clienteleInfo?.descriptions
    ?.map((desc) => desc.name)
    .join(', ')
  const clienteleInterestFormatted = clienteleInfo?.interests
    ?.map((interest) => interest.name)
    .join(', ')
  return {
    basicInfoFormatted,
    clienteleDescriptionFormatted,
    clienteleInterestFormatted,
  }
}
