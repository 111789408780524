import { useSelector } from 'react-redux'
import styled from 'styled-components'

import {
  isAccountTypeBrand,
  userCanFindConnections,
} from 'store/currentUser/selectors'

import { DISCOVER_BRANDS, PASSPORT_LANDING } from 'routes/paths'

import { IDiscoverDropdownState } from '../../navbarRevamp.types'
import DropdownMenuGroup from '../DropdownMenuGroup/DropdownMenuGroup'
import DropdownMenuTextLink from '../DropdownMenuTextLink/DropdownMenuTextLink'
import NavbarDropdown from '../NavbarDropdown/NavbarDropdown'
import Trigger from '../Trigger/Trigger'
import messages from 'features/Navbar/NavbarRevamp/constants/navbarRevamp.messages'
import { DiscoverDropdownIds } from 'features/Navbar/NavbarRevamp/navbarRevamp.ids'

const NavbarDiscoverDropdownContainer = styled.div`
  display: flex;
`

const NavbarDiscoverDropdown = () => {
  const { canFindConnections, isBrandAccount } = useSelector<
    object,
    IDiscoverDropdownState
  >((state) => ({
    canFindConnections: userCanFindConnections(state),
    isBrandAccount: isAccountTypeBrand(state),
  }))

  if (isBrandAccount) {
    return null
  }

  return (
    <NavbarDiscoverDropdownContainer>
      <NavbarDropdown
        trigger={
          <Trigger
            label={messages.discover}
            id={DiscoverDropdownIds.DiscoverTrigger}
          />
        }
      >
        <DropdownMenuGroup>
          {canFindConnections && (
            <DropdownMenuTextLink
              to={DISCOVER_BRANDS}
              message={messages.findNewBrands}
              id={DiscoverDropdownIds.DiscoverOptionFindNewBrands}
            />
          )}
          <DropdownMenuTextLink
            to={PASSPORT_LANDING}
            message={messages.passport}
            id={DiscoverDropdownIds.DiscoverOptionPassport}
          />
        </DropdownMenuGroup>
      </NavbarDropdown>
    </NavbarDiscoverDropdownContainer>
  )
}

export default NavbarDiscoverDropdown
