import { useEffect, useState } from 'react'

import qs from 'qs'
import { matchPath, useLocation } from 'react-router-dom'

import { PASSPORT_EVENT, PUBLIC_RETAILER_PROFILE } from 'routes/paths'

import { PRIVATE_LOC_SEARCH } from 'features/Passport/utils'

const SCROLL_Y_LIMIT = 226

export const useShowLoginButton = () => {
  const location = useLocation()
  const path = location.pathname
  const isPublicRetailerProfile = matchesWithPath({
    path,
    matcher: PUBLIC_RETAILER_PROFILE,
  })
  const isEventLanding = matchesWithPath({
    path,
    matcher: PASSPORT_EVENT,
  })
  const isPrivateEvent = Boolean(
    qs.parse(location.search)?.[PRIVATE_LOC_SEARCH],
  )

  const conditionToListenScroll =
    isPublicRetailerProfile || (isEventLanding && isPrivateEvent)

  const [showLoginButton, setShowLoginButton] = useState(
    !conditionToListenScroll,
  )

  useEffect(() => {
    const handleScroll = () => {
      if (conditionToListenScroll) {
        const scrollPosition = window.scrollY
        setShowLoginButton(scrollPosition >= SCROLL_Y_LIMIT)
      }
    }
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [conditionToListenScroll])

  return showLoginButton
}

const matchesWithPath = ({
  path,
  matcher,
}: {
  path: string
  matcher: string
}) => Boolean(matchPath(path, { path: matcher, exact: true }))
