import get from 'lodash/get'

export type UserFromQuery = {
  apiHost: string
  targetAccountId: string
  loggedAsAdmin: boolean
  isAdmin: boolean
  isOwner: boolean
}

type CurrentAccountInfo = {
  accountId: string
  profileName: string
  logoURL: string
  type: number
  canTransact: boolean
  canViewDataPortal: boolean
  limitedAccess: boolean
  coreBrand: boolean
  canViewAssortments: boolean
  canShop: boolean
  canFindConnections: boolean
  origin: string
}

export type CurrentUserInfo = {
  apiHost: string
  loggedAsAdmin: boolean
  isAdmin: boolean
  isOwner: boolean
  account: CurrentAccountInfo
  assortmentPlanning: boolean
  canViewRetailerSettings: boolean
  integrations: string
  isTiedWithBuyerHierarchy: boolean
  settings: any
  id: string
  userId: string
  accountsUserId: string
  isInternal: boolean
  isLoggedIn: boolean
  name: string
  dateFormat: string
  email: string
  lastLogin: string
  emailLanguage: string
  displayLanguage: string
}
export const getUserInfo = (
  userFromConfig: UserFromQuery,
  fetchedUser: any,
): CurrentUserInfo => ({
  // From Config
  apiHost: userFromConfig.apiHost,
  loggedAsAdmin: userFromConfig.loggedAsAdmin,
  isAdmin: userFromConfig.isAdmin,
  isOwner: userFromConfig.isOwner,
  // From Backend
  account: {
    accountId: get(fetchedUser, 'account.accountId'),
    profileName: get(fetchedUser, 'account.profileName'),
    logoURL: get(fetchedUser, 'account.logoURL'),
    type: get(fetchedUser, 'account.type'), // Admin (1) Brand (2) Retailer (3)
    canTransact: get(fetchedUser, 'account.canTransact', false), // Brand
    canViewDataPortal:
      userFromConfig.isAdmin ||
      get(fetchedUser, 'account.brandCanViewDataPortal') ||
      get(fetchedUser, 'account.limitedAccess'), // Admin || Brand with canViewDataPortal || Lite Brand
    limitedAccess: get(fetchedUser, 'account.limitedAccess', false), // Lite Brand
    coreBrand: get(fetchedUser, 'account.coreBrand', false), // Core Brand
    canViewAssortments: get(fetchedUser, 'account.canViewAssortments', false), // Retailer and assortmentPlanning
    canShop: get(fetchedUser, 'account.canShop', false), // Retailer
    canFindConnections: get(fetchedUser, 'account.canFindBrands', false), // Retailer
    origin: get(fetchedUser, 'account.origin'), // external source origin from the account
  },
  assortmentPlanning: get(fetchedUser, 'assortmentPlanning', false), // Flag true for account and user
  canViewRetailerSettings: get(fetchedUser, 'canViewRetailerSettings', false), // Has feature access permissions for retailer settings
  integrations: get(fetchedUser, 'account.integrations'), // Names of integrations, RLM or Kellwood
  isTiedWithBuyerHierarchy: get(fetchedUser, 'isTiedWithBuyerHierarchy', false), // Checks for Retailer buyer hierarchy on brands for this account & user
  settings: get(fetchedUser, 'settings'), // All feature access permissions
  id: get(fetchedUser, 'id'),
  userId: get(fetchedUser, 'userId'),
  accountsUserId: get(fetchedUser, 'accountsUserId'), // Viewer id
  isInternal: get(fetchedUser, 'isInternal', false),
  isLoggedIn: get(fetchedUser, 'isLoggedIn', false), // Defined User ID
  name: get(fetchedUser, 'name'),
  dateFormat: get(fetchedUser, 'dateFormat'),
  email: get(fetchedUser, 'email'),
  lastLogin: get(fetchedUser, 'lastLogin'),
  emailLanguage: get(fetchedUser, 'emailLanguage'),
  displayLanguage: get(fetchedUser, 'displayLanguage'),
})

export const getLaunchDarklyUserInfo = (userInfo: any) => {
  const user = Boolean(userInfo.userId)
  const account = Boolean(userInfo.account.accountId)
  const name = (account && userInfo.account.profileName) || 'anonymous'
  return {
    key: (account && userInfo.account.accountId) || 'anonymous',
    name,
    ...(!account && { anonymous: true }),
    userId: user && userInfo.userId,
    accountId: account && userInfo.account.accountId,
    accountsUserId: user && userInfo.accountsUserId,
    isAdmin: user && userInfo.isAdmin,
    loggedAsAdmin: user && userInfo.loggedAsAdmin,
    accountProfileName: name,
    accountType: account && userInfo.account.type,
    isInternal: user && userInfo.isInternal,
    isLimitedAccess: account && userInfo.account.limitedAccess,
    isCoreBrand: account && userInfo.account.coreBrand,
    host: window.location.href,
  }
}
