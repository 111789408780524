import { RefObject, useEffect, useMemo, useState } from 'react'

export const useScreenSize = (limit: number) => {
  const [isUnderLimit, setIsUnderLimit] = useState(window.innerWidth <= limit)

  const onResize = () => {
    setIsUnderLimit(window.innerWidth <= limit)
  }

  useEffect(() => {
    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  return isUnderLimit
}

export const useScreenWidth = () => {
  const [width, setWidth] = useState(window.innerWidth)

  const onResize = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  return width
}

export const useOnScreen = (
  ref: RefObject<HTMLElement>,
  options?: IntersectionObserverInit,
): boolean => {
  const [isIntersecting, setIntersecting] = useState(false)

  const observer = useMemo(
    () =>
      new IntersectionObserver(
        ([entry]) => setIntersecting(entry.isIntersecting),
        options,
      ),
    [options],
  )

  useEffect(() => {
    ref?.current && observer.observe(ref.current)
    return () => {
      observer.disconnect()
    }
  }, [ref, observer])

  return isIntersecting
}
