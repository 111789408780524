export const BASIC_STYLES_UPLOADER_PREFIX = 'basic-styles-uploader'
export const BRAND_START_ORDER_MODAL = 'brand-start-order'
export const CUSTOM_PROFILE = 'custom-profile'
export const DISCOVER_BRANDS_PREFIX = 'discover-brands'
export const DOWNLOAD_ORDER_CONFIRMATION = 'Download-Order-Confirmation'
export const EARLY_ACCESS_PREFIX = 'early-access'
export const EXPORT_DATA = 'Export-Data-Modal'
export const EXTERNAL_PAYMENTS = 'external-payments'
export const FIND_NEW_RETAILERS_PREFIX = 'find-new-retailers'
export const HOME_DASHBOARD_PREFIX = 'home-dashboard'
export const IMPORT_ORDERS_PREFIX = 'import-orders'
export const JOOR_PAY_ORDERS_PREFIX = 'joor-pay-orders'
export const LIST_LINESHEETS_PREFIX = 'list-linesheets'
export const LITE_RETAILER_REQUIRED_INFO_PREFIX = 'lite-retailer-required-info'
export const MANAGE_LOOKS_PREFIX = 'manage_looks'
export const MANAGE_STYLEBOARDS = 'manage-styleboards'
export const MANAGE_STYLES_PREFIX = 'manage-styles'
export const MOVE_PRODUCTS_PREFIX = 'move-products'
export const NAVBAR_REVAMP_PREFIX = 'navbar-revamp'
export const ORDER_LIST_PREFIX = 'order-list'
export const ORDER_REVIEW = 'order-review'
export const PAYMENT_SETTINGS_PREFIX = 'payment-settings'
export const PERMISSION_SETTINGS_PREFIX = 'permission-settings'
export const PRODUCT_CATALOG = 'catalog'
export const REPORTS = 'reports'
export const RETAILER_PROFILE_PREFIX = 'retailer-profile'
export const RETAILER_START_ORDER_MODAL = 'retailer-start-order'
export const SUBMISSIONS_PREFIX = 'submissions'
export const UPLOAD_EXCEL_ORDER_MODAL = 'upload-excel-order'
export const UPLOAD_IMAGES_MODAL = 'upload-images-modal'
export const CANCEL_REPLACE_STYLE = 'cancel-replace-style'
