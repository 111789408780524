import { useQuery } from '@apollo/client'

import { atlasClient } from 'graphql/client'
import { atlas } from 'graphql/tags'

export const PAGE_SIZE = 15

const IMAGES = atlas`#graphql
  query brandImages($search: String, $offset: Int) {
    brandMedia(
      limit: ${PAGE_SIZE}
      offset: $offset
      filters: { searchFilename: $search, mediaType: IMAGE }
    ) {
      totalCount
      media {
        url
        id
        fileName
      }
    }
  }
`

export const useBrandImages = (fileName = '', offset = 0) => {
  const { loading, data } = useQuery(IMAGES, {
    client: atlasClient,
    notifyOnNetworkStatusChange: true,
    variables: {
      search: fileName,
      offset,
    },
    skip: fileName.length > 0 && fileName.length < 3,
  })
  const totalCount = data?.brandMedia?.totalCount || 1
  const totalPages = Math.ceil(totalCount / PAGE_SIZE)

  return {
    images: data?.brandMedia?.media || [],
    imagesLoading: loading,
    totalPages,
  }
}
