import { Component } from 'react'

import classNames from 'classnames'
import PropTypes from 'prop-types'

import { ReactComponent as iconEllipsis } from 'assets/images/Collections/icon_ellipsis.svg'
import { ReactComponent as iconStar } from 'assets/images/Collections/icon_star.svg'
import { ReactComponent as iconStarFavorited } from 'assets/images/Collections/icon_star_favorited.svg'
import { ReactComponent as iconAmericanExpress } from 'assets/images/CreditCards/american-express.svg'
import { ReactComponent as iconBank } from 'assets/images/CreditCards/bank.svg'
import { ReactComponent as iconDinersClub } from 'assets/images/CreditCards/diners-club.svg'
import { ReactComponent as iconDiscover } from 'assets/images/CreditCards/discover.svg'
import { ReactComponent as iconGenericCard } from 'assets/images/CreditCards/generic-card.svg'
import { ReactComponent as iconMasterCard } from 'assets/images/CreditCards/mastercard.svg'
import { ReactComponent as iconTreviPay } from 'assets/images/CreditCards/trevipay.svg'
import { ReactComponent as iconVisa } from 'assets/images/CreditCards/visa.svg'
import { ReactComponent as iconFullHeart } from 'assets/images/FavoritedYourProfileImages/icon_favorited_active.svg'
import { ReactComponent as iconHeart } from 'assets/images/PassportImages/icon_favorite.svg'
import { ReactComponent as iconSendToBack } from 'assets/images/Showroom/icon_send_to_back.svg'
import { ReactComponent as iconFacebook } from 'assets/images/SocialIcons/icon_facebook.svg'
import { ReactComponent as iconInstagram } from 'assets/images/SocialIcons/icon_instagram.svg'
import { ReactComponent as iconTwitter } from 'assets/images/SocialIcons/icon_twitter.svg'
import { ReactComponent as iconCheckmarkDefault } from 'assets/images/Storefront/icon_checkmark_default.svg'
import { ReactComponent as iconEye } from 'assets/images/Storefront/icon_eye.svg'
import { ReactComponent as iconMove } from 'assets/images/Storefront/icon_move.svg'
import { ReactComponent as iconPencil } from 'assets/images/Storefront/icon_pencil.svg'
import { ReactComponent as iconTrash } from 'assets/images/Storefront/icon_trash.svg'
import { ReactComponent as iconXCloseDefault } from 'assets/images/Storefront/icon_x_close_default.svg'
import { ReactComponent as icon3dShowroom } from 'assets/images/icon_3d_showroom.svg'
import { ReactComponent as iconAboutBrand } from 'assets/images/icon_about_brand.svg'
import { ReactComponent as iconAdd } from 'assets/images/icon_add.svg'
import { ReactComponent as iconAddProduct } from 'assets/images/icon_add_product.svg'
import { ReactComponent as iconAddProductHover } from 'assets/images/icon_add_product_hover.svg'
import { ReactComponent as iconArrowRight } from 'assets/images/icon_arrow.svg'
import { ReactComponent as iconAssignImageToAllColors } from 'assets/images/icon_assign_image_to_all_colors.svg'
import { ReactComponent as iconAssortmentLock } from 'assets/images/icon_assortment_lock.svg'
import { ReactComponent as iconAssortmentUnlock } from 'assets/images/icon_assortment_unlock.svg'
import { ReactComponent as iconAttachment } from 'assets/images/icon_attachment.svg'
import { ReactComponent as iconBackArrow } from 'assets/images/icon_back.svg'
import { ReactComponent as iconBrandDirectory } from 'assets/images/icon_brand_directory.svg'
import { ReactComponent as iconFilledCheckOffCircle } from 'assets/images/icon_check_off_circle_filled.svg'
import { ReactComponent as iconFilledCheckOnCircle } from 'assets/images/icon_check_on_circle_filled.svg'
import { ReactComponent as iconCheckmark } from 'assets/images/icon_checkmark.svg'
import { ReactComponent as iconCheckmarkInCircle } from 'assets/images/icon_checkmark_in_circle.svg'
import { ReactComponent as iconCheckmarkInCircleNoOutline } from 'assets/images/icon_checkmark_in_circle_no_outline.svg'
import { ReactComponent as iconChevron } from 'assets/images/icon_chevron.svg'
import { ReactComponent as iconCollectionGallery } from 'assets/images/icon_collection_gallery.svg'
import { ReactComponent as iconCollectionText } from 'assets/images/icon_collection_txt.svg'
import { ReactComponent as iconComingSoon } from 'assets/images/icon_coming_soon.svg'
import { ReactComponent as iconComments } from 'assets/images/icon_comments.svg'
import { ReactComponent as iconCopy } from 'assets/images/icon_copy.svg'
import { ReactComponent as iconCrop } from 'assets/images/icon_crop.svg'
import { ReactComponent as iconDeduplicate } from 'assets/images/icon_deduplicate.svg'
import { ReactComponent as iconDeselect } from 'assets/images/icon_deselect.svg'
import { ReactComponent as iconDelete } from 'assets/images/icon_discard.svg'
import { ReactComponent as iconDiscardCircle } from 'assets/images/icon_discard_circle.svg'
import { ReactComponent as iconDownload } from 'assets/images/icon_download.svg'
import { ReactComponent as iconDropup } from 'assets/images/icon_dropup.svg'
import { ReactComponent as iconDuplicate } from 'assets/images/icon_duplicate.svg'
import { ReactComponent as iconEdit } from 'assets/images/icon_edit_pencil.svg'
import { ReactComponent as iconEditDisabled } from 'assets/images/icon_edit_pencil_disabled.svg'
import { ReactComponent as iconEditText } from 'assets/images/icon_edit_text.svg'
import { ReactComponent as iconError } from 'assets/images/icon_error.svg'
import { ReactComponent as iconExclamationSemantic } from 'assets/images/icon_exclamation_semantic.svg'
import { ReactComponent as iconFilter } from 'assets/images/icon_filter.svg'
import { ReactComponent as iconFilterList } from 'assets/images/icon_filter_list.svg'
import { ReactComponent as iconFilterPassport } from 'assets/images/icon_filter_passport.svg'
import { ReactComponent as iconFlag } from 'assets/images/icon_flag.svg'
import { ReactComponent as iconGroupMode } from 'assets/images/icon_group.svg'
import { ReactComponent as iconGroupModeEnabled } from 'assets/images/icon_group_enabled.svg'
import { ReactComponent as iconHideNav } from 'assets/images/icon_hide_nav.svg'
import { ReactComponent as iconImage } from 'assets/images/icon_image.svg'
import { ReactComponent as iconImageGallery } from 'assets/images/icon_image_gallery.svg'
import { ReactComponent as iconImgTxt } from 'assets/images/icon_img_txt.svg'
import { ReactComponent as iconInfoBubble } from 'assets/images/icon_info.svg'
import { ReactComponent as iconInstagramGallery } from 'assets/images/icon_instagram_gallery.svg'
import { ReactComponent as iconLinesheetGallery } from 'assets/images/icon_linesheet_gallery.svg'
import { ReactComponent as iconLinesheetText } from 'assets/images/icon_linesheet_txt.svg'
import { ReactComponent as iconLink } from 'assets/images/icon_link.svg'
import { ReactComponent as iconLinkOut } from 'assets/images/icon_link_out.svg'
import { ReactComponent as iconLock } from 'assets/images/icon_lock.svg'
import { ReactComponent as iconLookbookGallery } from 'assets/images/icon_lookbook_gallery.svg'
import { ReactComponent as iconLookbookText } from 'assets/images/icon_lookbook_txt.svg'
import { ReactComponent as iconMediaBanner } from 'assets/images/icon_media_banner.svg'
import { ReactComponent as iconMenu } from 'assets/images/icon_menu.svg'
import { ReactComponent as iconMessage } from 'assets/images/icon_message.svg'
import { ReactComponent as iconMinus } from 'assets/images/icon_minus.svg'
import { ReactComponent as iconNew } from 'assets/images/icon_new.svg'
import { ReactComponent as iconNextArrow } from 'assets/images/icon_next.svg'
import { ReactComponent as iconNoImage } from 'assets/images/icon_no_image.svg'
import { ReactComponent as iconNoImageInRow } from 'assets/images/icon_no_image_in_row.svg'
import { ReactComponent as iconBellAlert } from 'assets/images/icon_notification.svg'
import { ReactComponent as iconOrb360Gallery } from 'assets/images/icon_orb360_gallery.svg'
import { ReactComponent as iconOrb360Text } from 'assets/images/icon_orb360_txt.svg'
import { ReactComponent as iconPaperPlane } from 'assets/images/icon_paper_plane.svg'
import { ReactComponent as iconPaste } from 'assets/images/icon_paste.svg'
import { ReactComponent as iconPerson } from 'assets/images/icon_person.svg'
import { ReactComponent as iconPillBox } from 'assets/images/icon_pillbox_close.svg'
import { ReactComponent as iconPIP } from 'assets/images/icon_pip.svg'
import { ReactComponent as iconPlay } from 'assets/images/icon_play.svg'
import { ReactComponent as iconPlus } from 'assets/images/icon_plus.svg'
import { ReactComponent as iconPriceSettingsQuickView } from 'assets/images/icon_price_settings_quick_view.svg'
import { ReactComponent as iconProductGallery } from 'assets/images/icon_product_gallery.svg'
import { ReactComponent as iconShowProductInfo } from 'assets/images/icon_product_info.svg'
import { ReactComponent as iconShowProductInfoEnabled } from 'assets/images/icon_product_info_enabled.svg'
import { ReactComponent as iconProfile } from 'assets/images/icon_profile.svg'
import { ReactComponent as iconSearchSmall } from 'assets/images/icon_search_small.svg'
import { ReactComponent as iconSelect } from 'assets/images/icon_select.svg'
import { ReactComponent as iconShare } from 'assets/images/icon_share.svg'
import { ReactComponent as iconShowNav } from 'assets/images/icon_show_nav.svg'
import { ReactComponent as iconSigma } from 'assets/images/icon_sigma.svg'
import { ReactComponent as iconSigmaDisabled } from 'assets/images/icon_sigma_disabled.svg'
import { ReactComponent as iconSortArrow } from 'assets/images/icon_sort_arrow.svg'
import { ReactComponent as iconStore } from 'assets/images/icon_store.svg'
import { ReactComponent as iconStorefrontCollection } from 'assets/images/icon_storefront_collection.svg'
import { ReactComponent as iconStorefrontImg } from 'assets/images/icon_storefront_img.svg'
import { ReactComponent as iconStorefrontLinehseet } from 'assets/images/icon_storefront_linesheet.svg'
import { ReactComponent as iconStorefrontLookbook } from 'assets/images/icon_storefront_lookbook.svg'
import { ReactComponent as iconStorefrontOrb } from 'assets/images/icon_storefront_orb360.svg'
import { ReactComponent as iconStorefrontProduct } from 'assets/images/icon_storefront_product.svg'
import { ReactComponent as iconNoSwatch } from 'assets/images/icon_swatch_no_color.svg'
import { ReactComponent as iconNoSwatchSmall } from 'assets/images/icon_swatch_no_color_small.svg'
import { ReactComponent as iconTableCell } from 'assets/images/icon_table_cell.svg'
import { ReactComponent as iconTag } from 'assets/images/icon_tag.svg'
import { ReactComponent as iconTextBanner } from 'assets/images/icon_text_banner.svg'
import { ReactComponent as iconTextBubble } from 'assets/images/icon_text_bubble.svg'
import { ReactComponent as iconUnlock } from 'assets/images/icon_unlock.svg'
import { ReactComponent as iconArrowUp } from 'assets/images/icon_up_arrow.svg'
import { ReactComponent as iconUpload } from 'assets/images/icon_upload.svg'
import { ReactComponent as iconVideo } from 'assets/images/icon_video.svg'
import { ReactComponent as iconVideoGallery } from 'assets/images/icon_video_gallery.svg'
import { ReactComponent as iconVideoText } from 'assets/images/icon_video_txt.svg'
import { ReactComponent as iconWarning } from 'assets/images/icon_warning.svg'
import { ReactComponent as closeXLarge } from 'assets/images/large_x_icon.svg'

import styles from './Icon.less'

export const AMERICAN_EXPRESS_ICON = 'americanExpress'
export const DINERS_CLUB_ICON = 'dinersClub'
export const DISCOVER_ICON = 'discover'
export const MASTERCARD_ICON = 'masterCard'
export const VISA_ICON = 'visa'
export const VISA_DEBIT_ICON = 'debit'
export const BANK = 'bank'
export const GENERIC_CARD_ICON = 'genericCard'
export const TEXT_BUBBLE = 'textBubble'
export const PAYPAL_ICON = 'paypal'
export const TREVIPAY_ICON = 'trevipay'

const nameToSvg = {
  'arrow-right': iconArrowRight,
  'bell-alert': iconBellAlert,
  'close-x': iconPillBox,
  'close-x-large': closeXLarge,
  'discard-circle': iconDiscardCircle,
  'edit-disabled': iconEditDisabled,
  'filter-list': iconFilterList,
  'filter-passport': iconFilterPassport,
  'locked-lock': iconLock,
  'unlocked-lock': iconUnlock,
  aboutBrand: iconAboutBrand,
  add: iconAdd,
  addProduct: iconAddProduct,
  addProductHover: iconAddProductHover,
  assignImageToAllColors: iconAssignImageToAllColors,
  assortmentLock: iconAssortmentLock,
  assortmentUnlock: iconAssortmentUnlock,
  backArrow: iconBackArrow,
  checkmark: iconCheckmark,
  checkmarkInCircle: iconCheckmarkInCircle,
  checkmarkInCircleNoOutline: iconCheckmarkInCircleNoOutline,
  chevron: iconChevron,
  collectionGallery: iconCollectionGallery,
  collectionText: iconCollectionText,
  comingSoon: iconComingSoon,
  comments: iconComments,
  commentBubble: iconMessage,
  crop: iconCrop,
  deduplicate: iconDeduplicate,
  delete: iconDelete,
  deselect: iconDeselect,
  download: iconDownload,
  dropup: iconDropup,
  duplicate: iconDuplicate,
  edit: iconEdit,
  editText: iconEditText,
  ellipsis: iconEllipsis,
  error: iconError,
  favoritedStar: iconStarFavorited,
  filter: iconFilter,
  flag: iconFlag,
  fullHeart: iconFullHeart,
  groupMode: iconGroupMode,
  groupModeEnabled: iconGroupModeEnabled,
  heart: iconHeart,
  hideNav: iconHideNav,
  image: iconImage,
  imageGallery: iconImageGallery,
  imageText: iconImgTxt,
  infoBubble: iconInfoBubble,
  instagramGallery: iconInstagramGallery,
  linesheetGallery: iconLinesheetGallery,
  linesheetText: iconLinesheetText,
  lookbookGallery: iconLookbookGallery,
  lookbookText: iconLookbookText,
  mediaBanner: iconMediaBanner,
  menu: iconMenu,
  minus: iconMinus,
  move: iconMove,
  nextArrow: iconNextArrow,
  noImage: iconNoImage,
  noImageInRow: iconNoImageInRow,
  noSwatch: iconNoSwatch,
  noSwatchSmall: iconNoSwatchSmall,
  orb360Gallery: iconOrb360Gallery,
  orb360Text: iconOrb360Text,
  paperPlane: iconPaperPlane,
  pencil: iconPencil,
  play: iconPlay,
  plus: iconPlus,
  priceSettingsQuickView: iconPriceSettingsQuickView,
  productGallery: iconProductGallery,
  searchSmall: iconSearchSmall,
  select: iconSelect,
  sendToBack: iconSendToBack,
  share: iconShare,
  showNav: iconShowNav,
  showProductInfo: iconShowProductInfo,
  showProductInfoEnabled: iconShowProductInfoEnabled,
  sigma: iconSigma,
  sortArrow: iconSortArrow,
  store: iconStore,
  storefrontCollection: iconStorefrontCollection,
  storefrontImg: iconStorefrontImg,
  storefrontLinesheet: iconStorefrontLinehseet,
  storefrontLookbook: iconStorefrontLookbook,
  storefrontOrb: iconStorefrontOrb,
  storefrontProduct: iconStorefrontProduct,
  person: iconPerson,
  profile: iconProfile,
  tableCell: iconTableCell,
  tag: iconTag,
  textBanner: iconTextBanner,
  trash: iconTrash,
  upload: iconUpload,
  video: iconVideo,
  videoGallery: iconVideoGallery,
  videoText: iconVideoText,
  'sigma-disabled': iconSigmaDisabled,
  arrowUp: iconArrowUp,
  warning: iconWarning,
  attachment: iconAttachment,
  eye: iconEye,
  exclamationSemantic: iconExclamationSemantic,
  xClose: iconXCloseDefault,
  checkMarkDefault: iconCheckmarkDefault,
  copy: iconCopy,
  paste: iconPaste,
  [AMERICAN_EXPRESS_ICON]: iconAmericanExpress,
  [BANK]: iconBank,
  [DINERS_CLUB_ICON]: iconDinersClub,
  [TREVIPAY_ICON]: iconTreviPay,
  [DISCOVER_ICON]: iconDiscover,
  [GENERIC_CARD_ICON]: iconGenericCard,
  [MASTERCARD_ICON]: iconMasterCard,
  [VISA_ICON]: iconVisa,
  new: iconNew,
  [TEXT_BUBBLE]: iconTextBubble,
  pip: iconPIP,
  star: iconStar,
  twitter: iconTwitter,
  facebook: iconFacebook,
  instagram: iconInstagram,
  brandDirectory: iconBrandDirectory,
  link: iconLink,
  linkOut: iconLinkOut,
  '3dShowroom': icon3dShowroom,
  checkCircleOn: iconFilledCheckOnCircle,
  checkCircleOff: iconFilledCheckOffCircle,
}

class Icon extends Component {
  state = { hasError: false }

  componentDidCatch() {
    this.setState({ hasError: true })
  }

  render() {
    const {
      name,
      className,
      label,
      onClick,
      style,
      active,
      disabled,
      containerClassName,
      testId,
      id,
    } = this.props
    const SvgIcon = nameToSvg[name]

    const accessibilityLabel = label || name.replace(/-/g, ' ')

    if (this.state.hasError) {
      return null
    }
    const conditionalStyles = {
      [styles.active]: active,
      [styles.disabled]: disabled,
    }

    return (
      <span
        onClick={disabled ? null : onClick}
        className={containerClassName}
        data-testid={testId}
        id={id}
      >
        <span
          data-testid="icon-wrapper"
          className={classNames(styles[name], className, conditionalStyles)}
        >
          <SvgIcon data-testid="icon" style={style} alt={accessibilityLabel} />
        </span>
      </span>
    )
  }
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.object,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  containerClassName: PropTypes.string,
  testId: PropTypes.string,
  id: PropTypes.string,
}

Icon.defaultProps = {
  className: null,
  label: null,
  onClick: () => {},
  style: {},
  active: false,
  disabled: false,
  containerClassName: '',
  testId: '',
  id: '',
}

export default Icon
