import { ChangeEvent, useState } from 'react'

import { MultiSelectDropdown, Option } from '@joor/design-system'
import { useDispatch, useSelector } from 'react-redux'

import { UPDATE_PROFILE } from 'store/storefront/constants'
import { getProfileInfo } from 'store/storefront/selectors'

import { Loader, Textarea } from 'components/Core'

import BrandDetailActions from './Actions/BrandDetailActions'
import { useProfileInfo } from './BrandDetails.hooks'
import * as S from './BrandDetails.styles'
import DragDropImage from './DragDropImg/DragDropImg'
import FormInput from './FormInput/FormInput'

const PROFILE_IMG: string = 'PROFILE_IMG'
const BRAND_LOGO: string = 'BRAND_LOGO'

export type Image = {
  id?: string
  url?: string
  mediaType?: string
  __typename?: string
}

export type ProductLine = {
  id: string
  name: string
  active: boolean
  __typename?: string
}

const BrandDetails: React.FC = () => {
  const dispatch = useDispatch()
  const profile = useSelector(getProfileInfo)
  const { loading, productLines } = useProfileInfo()
  const [isPictureResetValue, setResetPictureValue] = useState<boolean>(false)
  const [isLogoResetValue, setResetLogoValue] = useState<boolean>(false)

  const selectImage = (
    id: string,
    image?: Image,
    resetValue: boolean = false,
  ): void => {
    if (id === PROFILE_IMG) {
      dispatch({
        type: UPDATE_PROFILE,
        value: { accountMedia: image },
      })
    } else {
      dispatch({
        type: UPDATE_PROFILE,
        value: { logo: image },
      })
    }
    id === PROFILE_IMG
      ? setResetPictureValue(resetValue)
      : setResetLogoValue(resetValue)
  }
  const categoriesOptions: Option[] = productLines.map((item: ProductLine) => {
    return { label: item.name, value: item.id }
  })
  const handleCategoriesSelection = (
    option: Option,
    action: string = 'add',
  ): void => {
    const selectedProductLineIds = profile?.productLineIds
    dispatch({
      type: UPDATE_PROFILE,
      value: {
        productLineIds:
          action === 'remove'
            ? selectedProductLineIds.filter(
                (productLineId: string) => productLineId !== option.value,
              )
            : selectedProductLineIds.concat(option.value),
      },
    })
  }

  return (
    <S.Container>
      <Loader active={loading} />
      <S.Title>Details</S.Title>
      <S.Info>
        Brand Details help retailers find and identify your profile across JOOR.
        Optionally, it can also be displayed on your profile via the “About Us”
        section.
      </S.Info>
      <S.Subtitle>General Info</S.Subtitle>
      <S.Block>
        <S.Label>Profile Picture</S.Label>
        <DragDropImage
          cropImage
          alt="Profile Image"
          id={PROFILE_IMG}
          selectImage={selectImage}
          imgFile={profile?.accountMedia}
          messages={{
            requirements: 'JPG or PNG under 4MB',
            unsopported:
              'Unsopported file type. Please, upload JPG or PNG image file',
            limit: 'File too large. Please, upload JPG or PNG image under 4MB.',
          }}
          shouldOpenModal={isPictureResetValue}
        />
      </S.Block>
      <S.Block>
        <S.Label>Brand Logo</S.Label>
        <DragDropImage
          alt="Brand Logo"
          id={BRAND_LOGO}
          selectImage={selectImage}
          imgFile={profile?.logo}
          messages={{
            requirements: 'JPG, PNG or SVG under 4MB',
            unsopported:
              'Unsopported file type. Please, upload JPG, PNG or SVG image file',
            limit:
              'File too large. Please, upload JPG, PNG or SVG image under 4MB.',
          }}
          shouldOpenModal={isLogoResetValue}
        />
      </S.Block>
      <S.Block>
        <S.Label>Description</S.Label>
        <Textarea
          id="description"
          placeholder="Add your description here"
          defaultValue={profile?.description}
          onChange={(e: Event) =>
            dispatch({
              type: UPDATE_PROFILE,
              value: {
                description: (e?.target as HTMLTextAreaElement).value,
              },
            })
          }
        />
      </S.Block>
      <S.Block>
        <MultiSelectDropdown
          options={categoriesOptions}
          label="Categories"
          defaultValue={categoriesOptions.filter((item: Option) =>
            profile?.productLineIds.includes(item?.value),
          )}
          onSelect={({ selected }: { selected: Option }) =>
            handleCategoriesSelection(selected)
          }
          onDeselect={({ deselected }) =>
            handleCategoriesSelection(deselected, 'remove')
          }
        />
      </S.Block>
      <S.Block>
        <S.Label>Year Established</S.Label>
        <FormInput
          type="number"
          id="yearEstablished"
          value={profile?.yearEstablished}
          onChange={(e: ChangeEvent) =>
            dispatch({
              type: UPDATE_PROFILE,
              value: { yearEstablished: (e?.target as HTMLInputElement).value },
            })
          }
        />
      </S.Block>
      <S.Block>
        <S.Label>Wholesale Price Range (USD)</S.Label>
        <S.PricesContainer>
          <FormInput
            label="Minimum"
            type="number"
            id="wholesale_min"
            value={profile?.wholesaleMin}
            onChange={(e: ChangeEvent) => {
              dispatch({
                type: UPDATE_PROFILE,
                value: { wholesaleMin: (e?.target as HTMLInputElement).value },
              })
            }}
          />
          <span className="spacer">—</span>
          <FormInput
            label="Maximum"
            type="number"
            id="wholesale_max"
            value={profile?.wholesaleMax}
            onChange={(e: ChangeEvent) =>
              dispatch({
                type: UPDATE_PROFILE,
                value: { wholesaleMax: (e?.target as HTMLInputElement).value },
              })
            }
          />
        </S.PricesContainer>
      </S.Block>
      <S.Block>
        <S.Label>Retail Price Range (USD)</S.Label>
        <S.PricesContainer>
          <FormInput
            label="Minimum"
            type="number"
            id="retail_min"
            value={profile?.retailMin}
            onChange={(e: ChangeEvent) =>
              dispatch({
                type: UPDATE_PROFILE,
                value: { retailMin: (e?.target as HTMLInputElement).value },
              })
            }
          />
          <span className="spacer"> — </span>
          <FormInput
            label="Maximum"
            type="number"
            id="retail_max"
            value={profile?.retailMax}
            onChange={(e: ChangeEvent) =>
              dispatch({
                type: UPDATE_PROFILE,
                value: { retailMax: (e?.target as HTMLInputElement).value },
              })
            }
          />
        </S.PricesContainer>
      </S.Block>
      <S.Subtitle>URLs</S.Subtitle>
      <S.Block>
        <S.Label>Website URL</S.Label>
        <FormInput
          type="text"
          id="website_url"
          value={profile?.websiteUrl}
          onChange={(e: ChangeEvent) =>
            dispatch({
              type: UPDATE_PROFILE,
              value: { websiteUrl: (e?.target as HTMLInputElement).value },
            })
          }
        />
      </S.Block>
      <S.Block>
        <S.Label>Custom JOOR URL</S.Label>
        <FormInput
          type="text"
          id="joor_url"
          value={`https://www.jooraccess.com/${profile?.customUrlHandle}`}
          onChange={(e: ChangeEvent): void => {
            const rawURL =
              (e?.target as HTMLInputElement).value?.split(
                'https://www.jooraccess.com/',
              )?.[1] || ''
            dispatch({
              type: UPDATE_PROFILE,
              value: {
                customUrlHandle: rawURL,
              },
            })
          }}
        />
      </S.Block>
      <S.Subtitle>Social Links</S.Subtitle>
      <S.Block>
        <FormInput
          label="Facebook (Profile Page URL)"
          type="text"
          id="facebook"
          value={`https://facebook.com/${profile?.facebookLink}`}
          onChange={(e: ChangeEvent): void => {
            const rawURL =
              (e?.target as HTMLInputElement).value?.split(
                'https://facebook.com/',
              )?.[1] || ''
            dispatch({
              type: UPDATE_PROFILE,
              value: { facebookLink: rawURL },
            })
          }}
        />
        <S.Block>
          <FormInput
            label="Instagram Account Name"
            type="text"
            id="instagram"
            value={`@${profile?.instagramHandle}`}
            onChange={(e: ChangeEvent): void => {
              const change =
                (e?.target as HTMLInputElement).value?.split('@')?.[1] || ''
              dispatch({
                type: UPDATE_PROFILE,
                value: { instagramHandle: change },
              })
            }}
          />
        </S.Block>
        <S.Block>
          <FormInput
            label="Twitter Account Name"
            type="text"
            id="twitter"
            value={`https://twitter.com/${profile?.twitterHandle}`}
            onChange={(e: ChangeEvent): void => {
              const rawURL =
                (e?.target as HTMLInputElement).value?.split(
                  'https://twitter.com/',
                )?.[1] || ''
              dispatch({
                type: UPDATE_PROFILE,
                value: { twitterHandle: rawURL },
              })
            }}
          />
        </S.Block>
      </S.Block>
      <BrandDetailActions />
    </S.Container>
  )
}

export default BrandDetails
