import { matchPath } from 'react-router-dom'

import {
  PASSPORT_PATHS,
  PUBLIC_BRAND_PROFILE,
  PUBLIC_INSTAGRAM_LANDING,
  PUBLIC_NOT_FOUND,
  PUBLIC_RETAILER_PROFILE,
  PUBLIC_STOREFRONT,
} from 'routes/paths'

export function isAnonymousRoute() {
  const match = matchPath(window.location.pathname, {
    exact: true,
    path: [
      ...PASSPORT_PATHS,
      PUBLIC_BRAND_PROFILE,
      PUBLIC_STOREFRONT,
      PUBLIC_INSTAGRAM_LANDING,
      PUBLIC_NOT_FOUND,
      PUBLIC_RETAILER_PROFILE,
    ],
  })
  return match != null
}
