import { DATA_SELF_SERVE, EXPORT_TEMPLATES } from 'routes/paths'

import DropdownMenuTextLink from '../../DropdownMenuTextLink/DropdownMenuTextLink'
import messages from 'features/Navbar/NavbarRevamp/constants/navbarRevamp.messages'
import { SettingsDropdownIds } from 'features/Navbar/NavbarRevamp/navbarRevamp.ids'

const IntegrationSettings = ({
  isRetailerAccount,
  retailIntegrationSettings,
  isBrandAccount,
}: {
  isRetailerAccount: boolean
  retailIntegrationSettings: boolean
  isBrandAccount: boolean
}) => {
  if (isBrandAccount) {
    return (
      <DropdownMenuTextLink
        to={EXPORT_TEMPLATES}
        message={messages.integrationSettings}
        id={SettingsDropdownIds.SettingsOptionIntegrations}
      />
    )
  } else if (isRetailerAccount && retailIntegrationSettings) {
    return (
      <DropdownMenuTextLink
        to={DATA_SELF_SERVE}
        message={messages.integrationSettings}
        id={SettingsDropdownIds.SettingsOptionIntegrations}
      />
    )
  }
  return null
}
export default IntegrationSettings
