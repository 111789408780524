import { useMutation, useQuery } from '@apollo/client'
import { useAuth } from 'context/AuthProvider'
import { useSelector } from 'react-redux'

import {
  SetSelectedAccountMutationMutation,
  SetSelectedAccountMutationMutationVariables,
} from '__generated__/atlas-types'

import { relayConnectionToArray } from 'utils/transformations/graphql'

import { atlasClient } from 'graphql/client'
import { useRenderToasters } from 'hooks/useRenderToasters'
import {
  userIsAdmin,
  userIsLoggedIn,
  userIsLoggedInAsAdmin,
} from 'store/currentUser/selectors'

import { ADMIN_ACCOUNTS } from 'routes/paths'

import { SET_SELECTED_ACCOUNT_MUTATION } from './accountsDropdown.mutations'
import { USER_ACCOUNTS } from './accountsDropdown.queries'
import {
  GrapheneAccount,
  SetSelectedAccount,
} from 'features/Navbar/NavbarRevamp/navbarRevamp.types'
import { navigateToHome } from 'features/Navbar/NavbarRevamp/utils/navbar.utils'

export const useUserAccounts = () => {
  const { data } = useQuery(USER_ACCOUNTS)
  const userAccounts: GrapheneAccount[] =
    relayConnectionToArray(data?.viewer?.accounts)?.map(
      (account: GrapheneAccount) => account,
    ) || []

  return userAccounts.sort((a, b) =>
    a.name.toLowerCase() <= b.name.toLowerCase() ? -1 : 1,
  )
}

export const useSetSelectedAccount = () => {
  const [mutate] = useMutation<
    SetSelectedAccountMutationMutation,
    SetSelectedAccountMutationMutationVariables
  >(SET_SELECTED_ACCOUNT_MUTATION, {
    client: atlasClient,
  })
  const { login, isAuthenticated } = useAuth()
  const isLogged = useSelector((state) => userIsLoggedIn(state))

  const isLoggedAdmin = useSelector((state) => userIsLoggedInAsAdmin(state))
  const isAdmin = useSelector((state) => userIsAdmin(state))

  const authLogged = isAuthenticated && isLogged
  const adminImpersonate = isLoggedAdmin && isAdmin
  const { renderErrorToast } = useRenderToasters()

  const setSelectedAccount: SetSelectedAccount = (accountId) => {
    mutate({
      variables: { input: { account_id: accountId } },
    }).then((response) => {
      if (response?.data?.setSelectedAccount?.success) {
        navigateToHome()
      } else {
        if (authLogged) {
          adminImpersonate ? (window.location.href = ADMIN_ACCOUNTS) : login()
        } else {
          renderErrorToast({
            description: response.errors,
            title: 'There was an error setting the selected account',
            shouldCloseManually: true,
          })
        }
      }
    })
  }
  return { setSelectedAccount }
}

export const useOldSetSelectedAccount = () => {
  const [setSelectedAccount] = useMutation(SET_SELECTED_ACCOUNT_MUTATION, {
    client: atlasClient,
  })
  return setSelectedAccount
}
