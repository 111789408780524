import { ComboButton, Link } from '@joor/design-system'
import { useAuth } from 'context/AuthProvider'
import {
  EXTERNAL_ABOUT,
  EXTERNAL_BLOG,
  EXTERNAL_BRANDS,
  EXTERNAL_DEMO_REQUEST,
  EXTERNAL_RETAILERS,
} from 'externalUrls'
import { useSelector } from 'react-redux'
import styled from 'styled-components/macro'

import { ReactComponent as JOORLogo } from 'assets/images/logo_joor_dark.svg'
import { userIsLoggedIn } from 'store/currentUser/selectors'

import { LEGACY_RETAIL_REGISTRATION, PASSPORT_LANDING } from 'routes/paths'

import NavbarRevamp from '../NavbarRevamp/NavbarRevamp.component'
import { LanguageSelector } from './LanguageSelector'
import { useShowLoginButton } from './PublicNavbar.hooks'
import { messages } from './messages'

export const PublicNavbarTestIds = {
  LanguageSelector: 'PublicNavbar.LanguageSelector',
  LoginButton: 'PublicNavbar.LoginButton',
} as const

const PublicNavbar = () => {
  const isLoggedIn = useSelector((state) => userIsLoggedIn(state))

  if (isLoggedIn) {
    return <NavbarRevamp />
  }

  return <Navbar />
}

const Navbar = () => {
  const { login } = useAuth()

  const showLoginButton = useShowLoginButton()

  const origin = window.location.origin
  const PASSPORT_URL = `${origin}${PASSPORT_LANDING}`

  return (
    <Nav>
      <Container>
        <JOORLogo width="144px" height="48px" />
        <Link fontSize="medium" href={EXTERNAL_BRANDS}>
          {messages.forBrandsLink}
        </Link>
        <Link fontSize="medium" href={EXTERNAL_RETAILERS}>
          {messages.forRetailersLink}
        </Link>
        <Link fontSize="medium" href={EXTERNAL_ABOUT}>
          {messages.aboutLink}
        </Link>
        <Link fontSize="medium" href={EXTERNAL_BLOG}>
          {messages.blogLink}
        </Link>
        <Link fontSize="medium" href={PASSPORT_URL}>
          {messages.passportLink}
        </Link>
        {showLoginButton && (
          <StyledComboButton
            testId={PublicNavbarTestIds.LoginButton}
            onClick={() => login()}
            options={[
              {
                label: (
                  <Link
                    fontSize="small"
                    color="primary-900"
                    href={EXTERNAL_DEMO_REQUEST}
                  >
                    {messages.requestDemoLink}
                  </Link>
                ),
                onClick: () => {},
              },
              {
                label: (
                  <Link
                    fontSize="small"
                    color="primary-900"
                    href={LEGACY_RETAIL_REGISTRATION}
                  >
                    {messages.retailerSignUpLink}
                  </Link>
                ),
                onClick: () => {},
              },
            ]}
          >
            {messages.logInButton}
          </StyledComboButton>
        )}

        <LanguageSelector testId={PublicNavbarTestIds.LanguageSelector} />
      </Container>
    </Nav>
  )
}

const Nav = styled.nav`
  position: fixed;
  margin: 0;
  width: 100%;
  top: 0;
  left: 0;
  right: auto;
  bottom: auto;
  z-index: 20000;
`

const Container = styled.div`
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 96px;
  background-color: var(--color-secondary-200);
  padding: 0 40px;
`

const StyledComboButton = styled(ComboButton)`
  &:first-child {
    min-width: 190px;
    font-size: 12px;
  }
`

export default PublicNavbar
