import styled from 'styled-components'

export const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 48px;
  padding: 0 32px;
  @media only screen and (max-width: 1199px) {
    padding-bottom: 16px;
    margin-left: 0;
  }
  @media only screen and (min-width: 1024px) {
    flex-direction: row;
    padding: 0 0 0 32px;
    align-items: flex-start;
    gap: 32px;
    flex-basis: 30%;
  }
`
export const StyledDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  @media only screen and (min-width: 1200px) {
    max-width: 100%;
    word-break: break-word;
  }
`

type StyledDescriptionProps = {
  $maxHeight: string
}
export const StyledDescription = styled.div<StyledDescriptionProps>`
  overflow: hidden;
  max-height: ${({ $maxHeight }): string => $maxHeight};
  word-break: break-word;
  white-space: pre-wrap;
  color: var(--color-primary-500);
  font-family: var(--font-family-alpha);
  font-size: var(--size-font-small);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-16);
  letter-spacing: var(--letter-spacing-tighter);
  margin-bottom: 8px;
`
export const EmptyDescriptionMessage = styled.div`
  font-family: var(--font-family-alpha);
  font-size: var(--size-font-small);
  font-weight: var(--font-weight-medium);
  line-height: 16px;
  letter-spacing: 0.6px;
  color: var(--color-primary-300);
`
export const StyledMediaContainer = styled.div`
  display: flex;
  gap: 16px;
`
export const StyledAnchor = styled.a`
  color: var(--color-secondary-800) !important;
  font-family: var(--font-family-alpha);
  font-size: var(--size-font-small);
  font-weight: var(--font-weight-medium);
  line-height: var(--size-font-medium);
  letter-spacing: var(--letter-spacing-tighter);
`
export const StyledAwardBadge = styled.img`
  width: 88px;
  height: 88px;
  margin-right: 16px;
`
