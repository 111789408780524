import React from 'react'

import { Button } from '@joor/design-system'
import { EXTERNAL_DEMO_REQUEST } from 'externalUrls'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { isLiteBrand } from 'store/currentUser/selectors'

import CreateOrderTemplateModal from 'modals/OrderList/CreateOrderTemplateModal'
import ExcelOrdersModal from 'modals/OrderList/ExcelOrdersModal'
import ExcelSummaryModal from 'modals/OrderList/ExcelSummaryModal'
import StartOrderModal from 'modals/OrderList/StartOrderModal'
import { ACCOUNTS_HOME, ORDER_LIST } from 'routes/paths'

import DropdownMenuGroup from '../DropdownMenuGroup/DropdownMenuGroup'
import DropdownMenuOption from '../DropdownMenuOption/DropdownMenuOption'
import DropdownMenuTextLink from '../DropdownMenuTextLink/DropdownMenuTextLink'
import messages from 'features/Navbar/NavbarRevamp/constants/navbarRevamp.messages'
import { OrdersDropdownIds } from 'features/Navbar/NavbarRevamp/navbarRevamp.ids'
import {
  INavbarOrdersDropdown,
  INavbarOrdersMenuDropdown,
} from 'features/Navbar/NavbarRevamp/navbarRevamp.types'

const ButtonWrapper = styled.div`
  text-align: center;
  border-top: 1px solid var(--color-primary-400);
  padding-top: 16px;
`

export const NavbarOrdersMenu = ({
  openDynamicModal,
}: INavbarOrdersDropdown) => {
  const { isLiteBrandAccount } = useSelector<object, INavbarOrdersMenuDropdown>(
    (state) => ({
      isLiteBrandAccount: isLiteBrand(state),
    }),
  )

  const openExcelSummaryModal = () => {
    openDynamicModal({
      component: ExcelSummaryModal,
      openExcelOrdersModal: openExcelOrdersModal,
    })
  }

  const openCreateOrderTemplateModal = () => {
    openDynamicModal({
      component: CreateOrderTemplateModal,
      openExcelOrdersModal: openExcelOrdersModal,
    })
  }

  const openExcelOrdersModal = () => {
    openDynamicModal({
      component: ExcelOrdersModal,
      openCreateOrderTemplateModal: openCreateOrderTemplateModal,
      openExcelSummaryModal: openExcelSummaryModal,
    })
  }
  const OrdersMenu = () => (
    <DropdownMenuGroup>
      <DropdownMenuTextLink
        to={ORDER_LIST}
        message={messages.manageOrders}
        id={OrdersDropdownIds.OrdersOptionOrders}
      />
      <DropdownMenuOption
        message={messages.startAnOrder}
        id={OrdersDropdownIds.OrdersOptionStartOrder}
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          event.stopPropagation()
          openDynamicModal({
            component: StartOrderModal,
          })
        }}
      />
      <DropdownMenuOption
        message={messages.uploadExcelOrders}
        id={OrdersDropdownIds.OrdersOptionUploadExcel}
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          event.stopPropagation()
          openExcelOrdersModal()
        }}
      />
    </DropdownMenuGroup>
  )

  const DemoOrdersMenu = () => (
    <DropdownMenuGroup>
      <DropdownMenuTextLink
        to={ACCOUNTS_HOME}
        target={EXTERNAL_DEMO_REQUEST}
        message={messages.isJoorProFeature}
        id={OrdersDropdownIds.OrdersProFeature}
        testId={OrdersDropdownIds.OrdersProFeature}
      />
      <ButtonWrapper>
        <Button
          id={OrdersDropdownIds.OrdersProFeatureLearnMore}
          onClick={() => {
            window.open(EXTERNAL_DEMO_REQUEST)
          }}
        >
          {messages.learnMore}
        </Button>
      </ButtonWrapper>
    </DropdownMenuGroup>
  )

  return isLiteBrandAccount ? <DemoOrdersMenu /> : <OrdersMenu />
}
