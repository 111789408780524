import { Icon, Text } from '@joor/design-system'
import { useFlags } from '@joor/launchdarkly-react-client-sdk'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'

import { formatConnectedAccounts, formatDate } from 'utils/formatters'

import { getDateFormat } from 'store/currentUser/selectors'

import { IDropdownMenuOptionAccounts } from 'features/Navbar/NavbarRevamp/navbarRevamp.types'

const MenuText = styled.div`
  font-size: var(--size-font-small);
  font-weight: var(--font-weight-regular);
  font-family: var(--font-family-alpha);
  letter-spacing: var(--letter-spacing-tighter);
  line-height: var(--line-height-16);
  padding: 8px;
  width: 100%;

  &:hover {
    border: 1px solid var(--color-primary-400);
    border-radius: 10px;
    padding: 7px;
  }
`

const sharedTextStyles = css`
  font-family: var(--font-family-alpha);
  font-size: var(--size-font-base);
  line-height: var(--line-height-16);
  letter-spacing: var(--letter-spacing-normal);
  font-weight: var(--font-weight-medium);
`

const MenuTextMessage = styled.span`
  ${sharedTextStyles}
  color: var(--color-primary-800);
  text-align: left;
`

const MenuTextMessageChecked = styled.span`
  ${sharedTextStyles}
  color: var(--color-secondary-800);
  text-align: left;
`

const MenuTextMessageCheck = styled.div`
  ${sharedTextStyles}
  color: var(--color-secondary-800);
  text-align: center;
  display: flex;

  svg {
    --main-color: var(--color-secondary-800);
    width: 16px;
    height: 16px;
  }
`

const MenuConnectedAccounts = styled.span`
  color: var(--color-primary-600);
  font-family: var(--font-family-alpha);
  font-size: var(--size-font-small);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-16);
  letter-spacing: var(--letter-spacing-tighter);
  text-align: left;
  margin-top: 4px;
`

const MenuTextAction = styled.button`
  background-color: var(--color-primary-100);
  border: none;
  text-align: left;
  padding: 0;
  cursor: pointer;
  text-decoration-line: none;
  margin: auto 0;
  display: flex;
  line-height: var(--line-height-16);
  width: 100%;

  &.primary {
    border: 1px solid var(--color-accent-beta-700);
    border-radius: 10px;
  }
`
const PrivacyWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const TextIconsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
`

const PrimaryWrapper = styled.div`
  display: flex;
  align-items: center;
  color: var(--color-accent-beta-700);

  path {
    fill: var(--color-accent-beta-700) !important;
  }
`

const DateWrapper = styled.div`
  margin-top: 4px;
  display: flex;
  margin-left: auto;
  justify-content: end;
`

const DropdownMenuOptionAccounts = ({
  className,
  message,
  connectedAccounts = [],
  onClick,
  testId,
  id,
  checked,
  isPrimary,
  createdAt,
  privacy,
}: IDropdownMenuOptionAccounts) => {
  const userDateFormat = useSelector(getDateFormat) as string
  const { lrPrimaryAccount } = useFlags()
  const formattedDate = formatDate(createdAt, userDateFormat)

  return (
    <MenuTextAction
      data-testid={testId}
      id={id}
      className={`menuTextLink ${
        isPrimary && lrPrimaryAccount ? 'primary' : ''
      }`}
    >
      <MenuText
        className={className}
        onClick={(event) => onClick && onClick(event)}
      >
        <div>
          {isPrimary && lrPrimaryAccount && (
            <PrimaryWrapper>
              <Icon iconName="pushpin" plain iconSize="small" />
              <Text
                fontFamily="numeric"
                fontSize="extrasmall"
                color="accent-beta-700"
              >
                PRIMARY
              </Text>
            </PrimaryWrapper>
          )}
          {!checked ? (
            <PrivacyWrapper>
              <MenuTextMessage>{message}</MenuTextMessage>
              {lrPrimaryAccount && (
                <Icon
                  iconName={privacy ? 'visibility-on' : 'visibility-off'}
                  iconSize="small"
                  color="primary-800"
                  plain
                />
              )}
            </PrivacyWrapper>
          ) : (
            <TextIconsWrapper>
              <PrivacyWrapper>
                <MenuTextMessageChecked>{message}</MenuTextMessageChecked>
              </PrivacyWrapper>
              <MenuTextMessageCheck>
                {lrPrimaryAccount && (
                  <Icon
                    iconName={privacy ? 'visibility-on' : 'visibility-off'}
                    iconSize="small"
                    color="primary-800"
                    plain
                  />
                )}
                <Icon iconName="checkmark" plain />
              </MenuTextMessageCheck>
            </TextIconsWrapper>
          )}
          {!!connectedAccounts.length && (
            <MenuConnectedAccounts>
              {formatConnectedAccounts(connectedAccounts)}
            </MenuConnectedAccounts>
          )}
          {createdAt && lrPrimaryAccount && (
            <DateWrapper>Created date: {formattedDate}</DateWrapper>
          )}
        </div>
      </MenuText>
    </MenuTextAction>
  )
}

export default DropdownMenuOptionAccounts
