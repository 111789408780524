import React from 'react'

import { useFlags } from '@joor/launchdarkly-react-client-sdk'
import compose from 'lodash/flowRight'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { resetSelectedAssortments } from 'store/assortmentList/actions'
import {
  setArchivedFilter,
  setBrandNoteFilter,
} from 'store/assortmentListFilters/actions'
import {
  SELECTED_TAB_PARAM,
  WORKSPACES_TAB_VALUES,
} from 'store/assortmentListFilters/constants'
import { isProRetailer } from 'store/currentUser/selectors'

import { ASSORTMENT_LIST, WORKSPACE_LIST } from 'routes/paths'

import DropdownMenuGroup from '../DropdownMenuGroup/DropdownMenuGroup'
import DropdownMenuTextLink from '../DropdownMenuTextLink/DropdownMenuTextLink'
import MenuLinkWrapper from '../MenuLinkWrapper/MenuLinkWrapper'
import NavbarDropdown from '../NavbarDropdown/NavbarDropdown'
import Trigger from '../Trigger/Trigger'
import withModal from 'containers/ModalHandler'
import messages from 'features/Navbar/NavbarRevamp/constants/navbarRevamp.messages'
import { WorkspacesOptionIds } from 'features/Navbar/NavbarRevamp/navbarRevamp.ids'

const WorkspacesDropdownContainer = styled.div`
  display: flex;
`

const WorkspacesDropdown = () => {
  const { workspaceListPage } = useFlags()
  const isProRetailerAccount = useSelector<object, boolean>(isProRetailer)
  const dispatch = useDispatch()

  const handleMenuOptionClick = (
    brandNoteFilter: boolean,
    archiveFilter: boolean,
  ) => {
    dispatch(resetSelectedAssortments())
    dispatch(setBrandNoteFilter(brandNoteFilter))
    dispatch(setArchivedFilter(archiveFilter))
  }

  if (!isProRetailerAccount) {
    return null
  }

  if (!workspaceListPage) {
    return (
      <MenuLinkWrapper
        to={ASSORTMENT_LIST}
        id={WorkspacesOptionIds.WorkspacesTrigger}
        data-testid={WorkspacesOptionIds.WorkspacesTrigger}
      >
        {messages.assortments}
      </MenuLinkWrapper>
    )
  }

  return (
    <WorkspacesDropdownContainer>
      <NavbarDropdown
        trigger={
          <Trigger
            label={messages.workspaces}
            id={WorkspacesOptionIds.WorkspacesTrigger}
          />
        }
      >
        <DropdownMenuGroup>
          <DropdownMenuTextLink
            message={messages.manageWorkspaces}
            id={WorkspacesOptionIds.WorkspacesOption}
            testId={WorkspacesOptionIds.WorkspacesOption}
            to={`${WORKSPACE_LIST}?${SELECTED_TAB_PARAM}=${WORKSPACES_TAB_VALUES.WORKSPACES}`}
            onClick={() => {
              handleMenuOptionClick(false, false)
            }}
          />
          <DropdownMenuTextLink
            message={messages.brandNotes}
            id={WorkspacesOptionIds.BrandNotesOption}
            testId={WorkspacesOptionIds.BrandNotesOption}
            to={`${WORKSPACE_LIST}?${SELECTED_TAB_PARAM}=${WORKSPACES_TAB_VALUES.BRAND_NOTES}`}
            onClick={() => {
              handleMenuOptionClick(true, false)
            }}
          />
          <DropdownMenuTextLink
            message={messages.archived}
            id={WorkspacesOptionIds.ArchivedOption}
            testId={WorkspacesOptionIds.ArchivedOption}
            to={`${WORKSPACE_LIST}?${SELECTED_TAB_PARAM}=${WORKSPACES_TAB_VALUES.ARCHIVED}`}
            onClick={() => {
              handleMenuOptionClick(false, true)
            }}
          />
        </DropdownMenuGroup>
      </NavbarDropdown>
    </WorkspacesDropdownContainer>
  )
}
export default compose(withModal)(WorkspacesDropdown)
