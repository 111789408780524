import sortBy from 'lodash/sortBy'

import {
  CarriedBrand,
  LocationType,
  ProductLine,
} from '__generated__/atlas-types'

import {
  StyledAddress,
  StyledContent,
  StyledSection,
  StyledSectionWithFlex,
  StyledSubSection,
  StyledSubtitle,
  StyledText,
  StyledTitle,
} from './LocationWidgetViewMode.styles'
import {
  getFormattedClienteleInfo,
  getFormattedLocation,
  useClienteleInfo,
} from './LocationWidgetViewMode.utils'
import { RetailerProfileLocation } from 'features/LeadGen/RetailerProfile/retailerProfilePage.types'

type LocationWidgetViewModeProps = {
  location: RetailerProfileLocation
}

const LocationAddress = ({ location }: LocationWidgetViewModeProps) => {
  const { formattedAddress, formattedLocationInfo } = getFormattedLocation(
    location,
  )
  return (
    <StyledSection>
      <StyledTitle>Address</StyledTitle>
      <StyledAddress
        className="noTranslate"
        data-testid={`location_addres1_address2_${location?.id}`}
      >
        <div>{formattedAddress}</div>
        <div>{formattedLocationInfo}</div>
        <div>{location.zip}</div>
        <div>{location.phone}</div>
      </StyledAddress>
    </StyledSection>
  )
}

const Clientele = ({
  clienteleInfo,
}: {
  clienteleInfo: RetailerProfileLocation['clienteleInfo']
}) => {
  const {
    basicInfoFormatted,
    clienteleDescriptionFormatted,
    clienteleInterestFormatted,
  } = getFormattedClienteleInfo(clienteleInfo)
  return (
    <StyledSection>
      <StyledSection>
        {!!basicInfoFormatted && (
          <StyledSubSection>
            <StyledSubtitle>Demographics</StyledSubtitle>
            <StyledText>{basicInfoFormatted}</StyledText>
          </StyledSubSection>
        )}
        {!!clienteleDescriptionFormatted && (
          <StyledSubSection>
            <StyledSubtitle>Described As</StyledSubtitle>
            <StyledText>{clienteleDescriptionFormatted}</StyledText>
          </StyledSubSection>
        )}
        {!!clienteleInterestFormatted && (
          <StyledSubSection>
            <StyledSubtitle>Shops For</StyledSubtitle>
            <StyledText>{clienteleInterestFormatted}</StyledText>
          </StyledSubSection>
        )}
      </StyledSection>
    </StyledSection>
  )
}

const Categories = ({ categories }: { categories: ProductLine[] }) => {
  const categoryNames = sortBy(categories, 'name')?.map(
    (category) => category.name,
  )
  return (
    <StyledSubSection>
      <StyledTitle>Categories</StyledTitle>
      <StyledSectionWithFlex>
        <StyledText>{categoryNames.join(', ')}</StyledText>
      </StyledSectionWithFlex>
    </StyledSubSection>
  )
}
const CarriedBrands = ({
  carriedBrands,
}: {
  carriedBrands: CarriedBrand[]
}) => {
  const carriedBrandNames = carriedBrands?.map(
    (carriedBrand) => carriedBrand.name,
  )
  return (
    <StyledSubSection>
      <StyledTitle>Brands Carried</StyledTitle>
      <StyledSection>
        <StyledText className="notranslate">
          {carriedBrandNames?.join(', ')}
        </StyledText>
      </StyledSection>
    </StyledSubSection>
  )
}

function LocationWidgetViewMode({ location }: LocationWidgetViewModeProps) {
  const { hasInfo: hasClienteleInfo } = useClienteleInfo(location.clienteleInfo)

  if (!location) return null

  const categories =
    !!location.categories && location.categories.length > 0
      ? location.categories
      : undefined

  const brandsCarried =
    !!location.brandsCarried && location.brandsCarried.length > 0
      ? location.brandsCarried
      : undefined

  const hasMarketInfo =
    !!location.type ||
    !!location.yearEstablished ||
    !!(location.wholesaleRange?.min || location.wholesaleRange?.max)

  return (
    <StyledContent>
      <div>
        {location.name && (
          <StyledSection>
            <StyledTitle>Location Name</StyledTitle>
            <StyledText className="notranslate">{location?.name}</StyledText>
          </StyledSection>
        )}
        <LocationAddress location={location} />
      </div>
      {hasMarketInfo && (
        <div>
          {location.type && (
            <StyledSection>
              <StyledTitle>Type</StyledTitle>
              <StyledText>
                {location?.type === LocationType.STORE ? 'Store' : 'Office'}
              </StyledText>
            </StyledSection>
          )}
          {location.yearEstablished && (
            <StyledSection>
              <StyledTitle>Year Established</StyledTitle>
              <StyledText className="notranslate">
                {location?.yearEstablished}
              </StyledText>
            </StyledSection>
          )}
          {(location.wholesaleRange?.min || location.wholesaleRange?.max) && (
            <StyledSection>
              <StyledTitle>Wholesale Price</StyledTitle>
              <StyledText className="notranslate">
                {`$${location.wholesaleRange?.min} - $${location.wholesaleRange?.max}`}
              </StyledText>
            </StyledSection>
          )}
        </div>
      )}
      {(categories || brandsCarried) && (
        <div>
          {categories && <Categories categories={categories} />}
          {brandsCarried && <CarriedBrands carriedBrands={brandsCarried} />}
        </div>
      )}
      {hasClienteleInfo && (
        <div>
          <Clientele clienteleInfo={location?.clienteleInfo} />
        </div>
      )}
    </StyledContent>
  )
}

export default LocationWidgetViewMode
