import { useQuery } from '@apollo/client'

import { NewSubmissionsDocument } from '__generated__/atlas-types'

import { atlasClient } from 'graphql/client'
import { atlas } from 'graphql/tags'

atlas`#graphql
  query NewSubmissions {
    submissions(filters: { status: NEW}) {
      totalCount
    }
  }
`

export const useNewSubmissions = (skip?: boolean) => {
  const { data } = useQuery(NewSubmissionsDocument, {
    client: atlasClient,
    skip,
    fetchPolicy: 'no-cache',
  })

  const count = data?.submissions?.totalCount || 0

  return count
}
