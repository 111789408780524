import styled from 'styled-components'

import { IDropdownMenuGroup } from 'features/Navbar/NavbarRevamp/navbarRevamp.types'

const DropdownMenuGroupContainer = styled.div`
  border-radius: 0px 0px 4px 4px;
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: var(--color-primary-100);
  border: 1px solid var(--color-primary-200);
  border-top: none;
  text-align: left !important;

  > .menuTextLink:not(:first-child) {
    border-top: 1px solid var(--color-primary-400);
    padding-top: 8px;
  }

  &:not(:first-child) {
    margin-top: 4px;
    border-radius: 4px;
    border-top: 1px solid var(--color-primary-200);
  }
`

const DropdownMenuGroup = ({ children }: IDropdownMenuGroup) => (
  <DropdownMenuGroupContainer className="dropdownMenuGroup">
    {children}
  </DropdownMenuGroupContainer>
)

export default DropdownMenuGroup
