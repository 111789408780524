import { useSelector } from 'react-redux'
import { generatePath } from 'react-router-dom'
import styled from 'styled-components'

import {
  getAccountId,
  isAccountTypeRetailer,
  isLiteBrand,
} from 'store/currentUser/selectors'

import {
  BRAND_PROFILE,
  DISCOVERY_DASHBOARD,
  LOOKBOOKS,
  MANAGE_BRAND_JOOR_URL,
  MANAGE_BRAND_PROFILE,
  PHOTO_GALLERY,
  PRESS,
  RETAILER_PROFILE,
  STOREFRONT,
} from 'routes/paths'

import { IProfileDropdownSelectors } from '../../navbarRevamp.types'
import DropdownMenuGroup from '../DropdownMenuGroup/DropdownMenuGroup'
import DropdownMenuTextLink from '../DropdownMenuTextLink/DropdownMenuTextLink'
import MenuLinkWrapper from '../MenuLinkWrapper/MenuLinkWrapper'
import NavbarDropdown from '../NavbarDropdown/NavbarDropdown'
import Trigger from '../Trigger/Trigger'
import { brandProfileMatchTypes } from 'features/Brand/BrandProfile/BrandProfile.constants'
import messages from 'features/Navbar/NavbarRevamp/constants/navbarRevamp.messages'
import { ProfileDropdownIds } from 'features/Navbar/NavbarRevamp/navbarRevamp.ids'

const NavbarProfileDropdownContainer = styled.div`
  display: flex;
`

const NavbarProfileDropdown = () => {
  const { accountId, isRetailerAccount, isLiteBrandAccount } = useSelector<
    object,
    IProfileDropdownSelectors
  >((state) => ({
    accountId: getAccountId(state),
    isRetailerAccount: isAccountTypeRetailer(state),
    isLiteBrandAccount: isLiteBrand(state),
  }))
  if (isRetailerAccount) {
    const retailerProfileLink = generatePath(RETAILER_PROFILE, {
      accountId,
    })
    return (
      <MenuLinkWrapper
        to={retailerProfileLink}
        id={ProfileDropdownIds.ProfileOptionsManage}
        data-testid={ProfileDropdownIds.ProfileOptionsManage}
      >
        {messages.manageProfile}
      </MenuLinkWrapper>
    )
  }
  const brandProfilePath = isLiteBrandAccount
    ? generatePath(BRAND_PROFILE, {
        accountId: accountId,
        matchType: brandProfileMatchTypes.NOT_MATCHED,
      })
    : generatePath(STOREFRONT, { accountId })

  return (
    <NavbarProfileDropdownContainer>
      <NavbarDropdown
        trigger={
          <Trigger
            label={messages.profile}
            id={ProfileDropdownIds.ProfileTrigger}
          />
        }
      >
        <DropdownMenuGroup>
          <DropdownMenuTextLink
            to={brandProfilePath}
            message={
              !isLiteBrandAccount
                ? messages.manageProfile
                : messages.viewProfile
            }
            id={ProfileDropdownIds.ProfileOptionsManage}
            testId={ProfileDropdownIds.ProfileOptionsManage}
          />
          {!isLiteBrandAccount && (
            <DropdownMenuTextLink
              to={DISCOVERY_DASHBOARD}
              message={messages.profileViews}
              id={ProfileDropdownIds.ProfileOptionsViews}
              testId={ProfileDropdownIds.ProfileOptionsViews}
            />
          )}
          <DropdownMenuTextLink
            to={MANAGE_BRAND_PROFILE}
            message={
              !isLiteBrandAccount
                ? messages.manageBrandDetails
                : messages.manageProfile
            }
            id={ProfileDropdownIds.ProfileOptionsProfile}
            testId={ProfileDropdownIds.ProfileOptionsProfile}
          />
          {!isLiteBrandAccount && (
            <DropdownMenuTextLink
              to={MANAGE_BRAND_JOOR_URL}
              message={messages.yourJOORUrl}
              id={ProfileDropdownIds.ProfileOptionsJOORUrl}
              testId={ProfileDropdownIds.ProfileOptionsJOORUrl}
            />
          )}
          <DropdownMenuTextLink
            to={LOOKBOOKS}
            message={messages.lookbooks}
            id={ProfileDropdownIds.ProfileOptionsLookbooks}
            testId={ProfileDropdownIds.ProfileOptionsLookbooks}
          />
          <DropdownMenuTextLink
            to={PRESS}
            message={messages.press}
            id={ProfileDropdownIds.ProfileOptionsPress}
            testId={ProfileDropdownIds.ProfileOptionsPress}
          />
          {!isLiteBrandAccount && (
            <DropdownMenuTextLink
              to={PHOTO_GALLERY}
              message={messages.photoGallery}
              id={ProfileDropdownIds.ProfileOptionsPhotoGallery}
              testId={ProfileDropdownIds.ProfileOptionsPhotoGallery}
            />
          )}
        </DropdownMenuGroup>
      </NavbarDropdown>
    </NavbarProfileDropdownContainer>
  )
}

export default NavbarProfileDropdown
