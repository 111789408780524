import { ApolloClient, ApolloLink } from '@apollo/client'
import { InMemoryCache, TypePolicies } from '@apollo/client/cache'
import { setContext } from '@apollo/client/link/context'
import { createUploadLink } from 'apollo-upload-client'
import { config } from 'config'
import { isEqual, merge } from 'lodash'

import { auth } from 'utils/sdks/authKeycloak'

import { getReduxStore } from 'store'

import getErrorLink from './errorLink'

type GetApolloClientArgs = {
  uri: string
  dispatch: (...args: any) => any
  typePolicies?: TypePolicies
  connectToDevTools?: boolean
}

type CacheFunctionExisting = any
type CacheFunctionIncoming = any

const setAuthorizationLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    authorization: auth.getToken() ? auth.getToken() : '',
  },
}))

export function getApolloClient({
  uri,
  dispatch,
  typePolicies = {},
  connectToDevTools = false,
}: GetApolloClientArgs) {
  // type version issues
  const httpLink = (createUploadLink({
    uri,
    credentials: 'include',
  }) as unknown) as ApolloLink
  const errorLink = getErrorLink(dispatch)

  return new ApolloClient({
    link: ApolloLink.from([setAuthorizationLink, errorLink, httpLink]),
    cache: new InMemoryCache({
      possibleTypes: {
        RetailerDoorAndClusterObject: ['RetailerDoor', 'RetailerCluster'],
      },
      typePolicies: merge(typePolicies, {
        Query: {
          fields: {
            public: {
              read(existing: CacheFunctionExisting) {
                return existing
              },
              merge(
                existing: CacheFunctionExisting,
                incoming: CacheFunctionIncoming,
              ) {
                return { ...existing, ...incoming }
              },
            },
            analytics: {
              read(existing: CacheFunctionExisting) {
                return existing
              },
              merge(
                existing: CacheFunctionExisting,
                incoming: CacheFunctionIncoming,
              ) {
                return { ...existing, ...incoming }
              },
            },
            searchBrandCategories: {
              read(existing: CacheFunctionExisting) {
                return existing
              },
              merge(
                existing: CacheFunctionExisting,
                incoming: CacheFunctionIncoming,
              ) {
                const merged = existing ? existing.slice(0) : []

                if (existing && isEqual(existing, incoming)) {
                  return existing
                } else {
                  return [...merged, ...(incoming || [])]
                }
              },
            },
            searchBrands: {
              read(
                existing: CacheFunctionExisting,
                {
                  args: { offset = 0, first = existing?.edges.length },
                }: CacheFunctionIncoming,
              ) {
                return {
                  edges:
                    existing?.edges &&
                    existing?.edges.slice(offset, offset + first),
                }
              },
              keyArgs: [
                'input',
                [
                  'connectionStatus',
                  'searchQuery',
                  'categories',
                  'minimumPrice',
                  'maximumPrice',
                  'sortBy',
                ],
              ],
              keyFields: [
                'input',
                [
                  'connectionStatus',
                  'searchQuery',
                  'categories',
                  'minimumPrice',
                  'maximumPrice',
                  'sortBy',
                ],
              ],
              merge(
                existing: CacheFunctionExisting,
                incoming: CacheFunctionIncoming,
              ) {
                const merged = existing?.edges ? existing.edges.slice(0) : []

                if (existing && isEqual(existing['edges'], incoming['edges'])) {
                  return existing
                } else {
                  return {
                    edges: [...merged, ...(incoming?.edges || [])],
                  }
                }
              },
            },
            searchProducts: {
              read(
                existing: CacheFunctionExisting,
                {
                  args: {
                    offset = 0,
                    first = existing?.matchedProducts.edges.length,
                  },
                }: CacheFunctionIncoming,
              ) {
                return {
                  matchedProducts: {
                    edges:
                      existing?.matchedProducts.edges &&
                      existing?.matchedProducts.edges.slice(
                        offset,
                        offset + first,
                      ),
                  },
                  aggregations: {
                    brands:
                      existing?.aggregations?.brands &&
                      existing?.aggregations?.brands.slice(
                        offset,
                        offset + first,
                      ),
                    categories:
                      existing?.aggregations?.categories &&
                      existing?.aggregations?.categories.slice(
                        offset,
                        offset + first,
                      ),
                    currency:
                      existing?.aggregations?.currency &&
                      existing?.aggregations?.currency.slice(
                        offset,
                        offset + first,
                      ),
                  },
                }
              },
              keyArgs: [
                'input',
                [
                  'connectionStatus',
                  'searchQuery',
                  'filters',
                  [
                    'brands',
                    'currencyCode',
                    'currencyType',
                    'categories',
                    'minimumPrice',
                    'maximumPrice',
                  ],
                ],
              ],
              keyFields: [
                'input',
                [
                  'connectionStatus',
                  'searchQuery',
                  'filters',
                  [
                    'brands',
                    'currencyCode',
                    'currencyType',
                    'categories',
                    'minimumPrice',
                    'maximumPrice',
                  ],
                ],
              ],
              merge(
                existing: CacheFunctionExisting,
                incoming: CacheFunctionIncoming,
              ) {
                const mergedProducts = existing?.matchedProducts.edges
                  ? existing.matchedProducts.edges.slice(0)
                  : []
                const mergedBrandAggregations = existing?.aggregations?.brands
                  ? existing?.aggregations?.brands.slice(0)
                  : []
                const mergedCategoryAggregations = existing?.aggregations
                  ?.categories
                  ? existing?.aggregations?.categories.slice(0)
                  : []
                const mergedCurrencyAggregations = existing?.aggregations
                  ?.currency
                  ? existing?.aggregations?.currency.slice(0)
                  : []

                if (
                  existing &&
                  isEqual(
                    existing.matchedProducts.edges,
                    incoming.matchedProducts.edges,
                  )
                ) {
                  return existing
                } else {
                  return {
                    matchedProducts: {
                      edges: [
                        ...mergedProducts,
                        ...(incoming?.matchedProducts.edges || []),
                      ],
                    },
                    aggregations: {
                      brands: [
                        ...new Set([
                          ...mergedBrandAggregations,
                          ...(incoming?.aggregations?.brands || []),
                        ]),
                      ],
                      categories: [
                        ...new Set([
                          ...mergedCategoryAggregations,
                          ...(incoming?.aggregations?.categories || []),
                        ]),
                      ],
                      currency: [
                        ...new Set([
                          ...mergedCurrencyAggregations,
                          ...(incoming?.aggregations?.currency || []),
                        ]),
                      ],
                    },
                  }
                }
              },
            },
            submissions: {
              keyArgs: ['filters', ['status']],
              merge(
                existing: CacheFunctionExisting,
                incoming: CacheFunctionIncoming,
              ) {
                const prev = existing ? existing : { edges: [] }
                return {
                  ...prev,
                  ...incoming,
                  edges: [...prev.edges, ...incoming.edges],
                }
              },
            },
          },
        },
        Order: {
          fields: {
            paymentInfo: {
              merge(
                existing: CacheFunctionExisting,
                incoming: CacheFunctionIncoming,
                { mergeObjects }: { mergeObjects: Function },
              ) {
                return mergeObjects(existing, incoming)
              },
            },
            products: {
              keyArgs: false,
              merge(
                existing: CacheFunctionExisting,
                incoming: CacheFunctionIncoming,
              ) {
                if (
                  existing &&
                  incoming &&
                  isEqual(existing.edges, incoming.edges)
                ) {
                  return incoming
                } else {
                  return {
                    ...existing,
                    ...incoming,
                    edges: [
                      ...(existing?.edges ?? []),
                      ...(incoming?.edges ?? []),
                    ],
                  }
                }
              },
            },
          },
        },
      }),
    }),
    connectToDevTools,
    defaultOptions: {
      mutate: {
        errorPolicy: 'all',
      },
    },
  })
}

const atlasUri = config.urls.atlas

export const atlasClient = getApolloClient({
  uri: atlasUri,
  dispatch: getReduxStore().dispatch,
  connectToDevTools: process.env.NODE_ENV === 'development',
})
