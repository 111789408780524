import { Suspense } from 'react'

import { useFlags } from '@joor/launchdarkly-react-client-sdk'
import { Redirect, Route, Switch } from 'react-router-dom'

import { featureFlagRouteWrapper } from 'utils/sdks/launchDarklyFeatureFlagRouteWrapper'

import Loader from 'components/Core/Loader/Loader'
import { REDIRECT_AFTER_SWITCH } from 'modals/OrderList/StartOrderModal/RetailerStartOrderModal/RetailerStartOrderModal.component'
import LegacyPage from 'pages/LegacyPage'
import RouterPage from 'pages/RouterPage/RouterPage'
import PrivateRoute from 'routes/PrivateRoute'
import {
  ASSORTMENT_LIST,
  HOME,
  SUBMISSIONS_VIEW,
  WORKSPACE_LIST,
} from 'routes/paths'
import {
  DEFAULT_ROUTES,
  PRIVATE_ROUTES,
  PUBLIC_ROUTES,
  RouteType,
} from 'routes/routes'

import styles from './PageContent.less'
import ExceptionHandler from 'containers/ExceptionHandler'
import FileDownloadHandler from 'containers/FileDownload/FileDownloadHandler.container'
import NewsBanner from 'containers/NewsBanner'
import NotificationHandler from 'containers/NotificationHandler/NotificationHandler.container'
import PageLoader from 'containers/PageLoader/PageLoader.container'
import EarlyAccessWidgetContainer from 'features/EarlyAccessWidget/components/EarlyAccessWidgetContainer/EarlyAccessWidgetContainer'

const wrapPageRoute = (route: RouteType) => (props: {}) => {
  const { component: WrappedComponent, path, title } = route
  return (
    <RouterPage path={path} title={title}>
      <WrappedComponent {...props} />
    </RouterPage>
  )
}

// TODO: Consolidate ExceptionHandler into NotificationHandler (ch6779)
const PageContent = () => {
  const { workspaceListPage } = useFlags()
  const redirectAfterSwitch = window.localStorage.getItem(REDIRECT_AFTER_SWITCH)
  const redirectAfterSwitchInfo = redirectAfterSwitch
    ? JSON.parse(redirectAfterSwitch)
    : null

  const getRedirectRoute = (route: RouteType, redirectTo: string) => (
    <Route
      exact
      key={route.path}
      path={route.path}
      component={(props: { location: { pathname: string } }) => (
        <Redirect
          to={props.location.pathname.replace(route.path, redirectTo)}
        />
      )}
    />
  )

  const getPageComponent = (route: RouteType) => {
    const wrappedComponent = wrapPageRoute(route)

    if (route.feature) {
      return (props: {}) =>
        featureFlagRouteWrapper(
          wrappedComponent,
          {
            flagKey: route.feature,
          },
          props,
        )
    }

    return wrappedComponent
  }

  const getPrivatePageComponent = (route: RouteType) => {
    const PageComponent = getPageComponent(route)
    return (props: {}) => (
      <PrivateRoute>
        <PageComponent {...props} />
      </PrivateRoute>
    )
  }

  return (
    <>
      <NewsBanner newsToDisplay={['upcomingMaintenanceBanner']} />
      <div className={styles.routerOutlet}>
        <EarlyAccessWidgetContainer />
        <Suspense fallback={workspaceListPage ? <></> : <Loader active />}>
          <Switch>
            {PRIVATE_ROUTES.map((route) => {
              if (
                route.path === HOME &&
                (redirectAfterSwitchInfo?.store?.brand?.id ||
                  redirectAfterSwitchInfo?.path.includes(
                    SUBMISSIONS_VIEW.replace(':submissionId', ''),
                  ))
              ) {
                return getRedirectRoute(route, redirectAfterSwitchInfo.path)
              }

              if (route.path === ASSORTMENT_LIST && workspaceListPage) {
                return getRedirectRoute(route, WORKSPACE_LIST)
              }

              return (
                <Route
                  exact
                  key={route.path}
                  path={route.path}
                  component={getPrivatePageComponent(route)}
                />
              )
            })}
            {PUBLIC_ROUTES.map((route) => {
              return (
                <Route
                  exact
                  key={route.path}
                  path={route.path}
                  component={getPageComponent(route)}
                />
              )
            })}
            {DEFAULT_ROUTES.map((route) => {
              return (
                <Route
                  exact
                  key={route.path}
                  path={route.path}
                  component={route.component}
                />
              )
            })}
            <Route component={(props: {}) => <LegacyPage {...props} />} />
          </Switch>
        </Suspense>
      </div>
      <NotificationHandler />
      <ExceptionHandler />
      <FileDownloadHandler />
      <PageLoader />
    </>
  )
}

export default PageContent
