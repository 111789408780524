import { RETAILER_PROFILE_PREFIX as FEATURE_PREFIX } from 'featurePrefix.ids'

const VIEW_MODE_PREFIX = 'view-mode'
const EDIT_MODE_PREFIX = 'edit-mode'
const BASIC_INFO_WIDGET_PREFIX = 'basic-info'
const BUYER_INFO_WIDGET_PREFIX = 'buyer-info'
const PEOPLE_SECTION_PREFIX = 'people-section'
const LOGO_WIDGET_PREFIX = 'logo'
const IMAGE_WIDGET_PREFIX = 'profile-image'
const BROWSE_IMAGE_MODAL_PREFIX = 'browse-image'
const LOCATION_WIDGET_PREFIX = 'location-widget'

export const ViewModeIds = {
  EditButton: `${FEATURE_PREFIX}__${VIEW_MODE_PREFIX}__edit-button`,
  AcceptButton: `${FEATURE_PREFIX}__${VIEW_MODE_PREFIX}__accept-button`,
  MessageButton: `${FEATURE_PREFIX}__${VIEW_MODE_PREFIX}__message-button`,
  ConnectButton: `${FEATURE_PREFIX}__${VIEW_MODE_PREFIX}__connect-button`,
  PeopleWidget: {
    Container: `${FEATURE_PREFIX}__${VIEW_MODE_PREFIX}__people-widget`,
    SectionTitle: {
      Retailer: `${FEATURE_PREFIX}__${VIEW_MODE_PREFIX}__people-widget__section-title__retailer`,
      Brand: `${FEATURE_PREFIX}__${VIEW_MODE_PREFIX}__people-widget__section-title__brand`,
    },
  },
  ContactButton: `${FEATURE_PREFIX}__${VIEW_MODE_PREFIX}__people-widget-contact`,
  ContactLink: `${FEATURE_PREFIX}__${VIEW_MODE_PREFIX}__people-widget-contact_link`,
} as const

export const EditModeIds = {
  AddLocationButton: `${FEATURE_PREFIX}__${EDIT_MODE_PREFIX}__add-location-button`,
  SaveButton: `${FEATURE_PREFIX}__${EDIT_MODE_PREFIX}__save-button`,
} as const

export const EditModeBasicInfoWidgetIds = {
  DescriptionInput: `${FEATURE_PREFIX}__${EDIT_MODE_PREFIX}__${BASIC_INFO_WIDGET_PREFIX}__description-input`,
  WebsiteInput: `${FEATURE_PREFIX}__${EDIT_MODE_PREFIX}__${BASIC_INFO_WIDGET_PREFIX}__website-input`,
  SocialMediaInputPrefix: `${FEATURE_PREFIX}__${EDIT_MODE_PREFIX}__${BASIC_INFO_WIDGET_PREFIX}__input-prefix`,
} as const

export const EditModeBuyerOwnerWidgetId = {
  BuyerInput: `${FEATURE_PREFIX}__${EDIT_MODE_PREFIX}__${BUYER_INFO_WIDGET_PREFIX}__buyer-input`,
  BuyerCheckbox: `${FEATURE_PREFIX}__${EDIT_MODE_PREFIX}__${BUYER_INFO_WIDGET_PREFIX}__buyer-checkbox`,
  OwnerCheckbox: `${FEATURE_PREFIX}__${EDIT_MODE_PREFIX}__${BUYER_INFO_WIDGET_PREFIX}__owner-checkbox`,
} as const

export const EditModePeopleSectionIds = {
  LegacyInfo: `${FEATURE_PREFIX}__${EDIT_MODE_PREFIX}__${PEOPLE_SECTION_PREFIX}__legacy-buyer-owner-info`,
  BuyerCard: `${FEATURE_PREFIX}__${EDIT_MODE_PREFIX}__${PEOPLE_SECTION_PREFIX}__buyer-card`,
  SectionTitle: `${FEATURE_PREFIX}__${EDIT_MODE_PREFIX}__${PEOPLE_SECTION_PREFIX}__section-title`,
} as const

export const EditModeLogoInfoWidgetIds = {
  UploadButton: `${FEATURE_PREFIX}__${EDIT_MODE_PREFIX}__${LOGO_WIDGET_PREFIX}__upload-button`,
  BrowseButton: `${FEATURE_PREFIX}__${EDIT_MODE_PREFIX}__${LOGO_WIDGET_PREFIX}__browse-button`,
} as const

export const EditModeProfileImageWidgetIds = {
  UploadButton: `${FEATURE_PREFIX}__${EDIT_MODE_PREFIX}__${IMAGE_WIDGET_PREFIX}__upload-button`,
  BrowseButton: `${FEATURE_PREFIX}__${EDIT_MODE_PREFIX}__${IMAGE_WIDGET_PREFIX}__browse-button`,
} as const

export const EditModeBrowseImageModalIds = {
  SearchInput: `${FEATURE_PREFIX}__${EDIT_MODE_PREFIX}__${BROWSE_IMAGE_MODAL_PREFIX}__search-input`,
  UploadButton: `${FEATURE_PREFIX}__${EDIT_MODE_PREFIX}__${BROWSE_IMAGE_MODAL_PREFIX}__upload-button`,
  ImagePrefix: `${FEATURE_PREFIX}__${EDIT_MODE_PREFIX}__${BROWSE_IMAGE_MODAL_PREFIX}__image-prefix`,
  CancelButton: `${FEATURE_PREFIX}__${EDIT_MODE_PREFIX}__${BROWSE_IMAGE_MODAL_PREFIX}__cancel-button`,
  SelectionButton: `${FEATURE_PREFIX}__${EDIT_MODE_PREFIX}__${BROWSE_IMAGE_MODAL_PREFIX}__selection-button`,
} as const

export const EditModeLocationWidgetIds = {
  DeleteButton: `${FEATURE_PREFIX}__${EDIT_MODE_PREFIX}__${LOCATION_WIDGET_PREFIX}__delete-button`,
  NameInput: `${FEATURE_PREFIX}__${EDIT_MODE_PREFIX}__${LOCATION_WIDGET_PREFIX}__name-input`,
  TypeDropdown: `${FEATURE_PREFIX}__${EDIT_MODE_PREFIX}__${LOCATION_WIDGET_PREFIX}__type-dropdown`,
  YearInput: `${FEATURE_PREFIX}__${EDIT_MODE_PREFIX}__${LOCATION_WIDGET_PREFIX}__year-input`,
  SelectionButton: `${FEATURE_PREFIX}__${EDIT_MODE_PREFIX}__${LOCATION_WIDGET_PREFIX}__selection-button`,
  WholesaleMinDropdown: `${FEATURE_PREFIX}__${EDIT_MODE_PREFIX}__${LOCATION_WIDGET_PREFIX}__wholesale-min-dropdown`,
  WholesaleMaxDropdown: `${FEATURE_PREFIX}__${EDIT_MODE_PREFIX}__${LOCATION_WIDGET_PREFIX}__wholesale-max-dropdown`,
  CountryDropdown: `${FEATURE_PREFIX}__${EDIT_MODE_PREFIX}__${LOCATION_WIDGET_PREFIX}__country-dropdown`,
  Address1Input: `${FEATURE_PREFIX}__${EDIT_MODE_PREFIX}__${LOCATION_WIDGET_PREFIX}__address-1-input`,
  Address2Input: `${FEATURE_PREFIX}__${EDIT_MODE_PREFIX}__${LOCATION_WIDGET_PREFIX}__address-2-input`,
  CityInput: `${FEATURE_PREFIX}__${EDIT_MODE_PREFIX}__${LOCATION_WIDGET_PREFIX}__city-input`,
  StateDropdown: `${FEATURE_PREFIX}__${EDIT_MODE_PREFIX}__${LOCATION_WIDGET_PREFIX}__state-dropdown`,
  ZipInput: `${FEATURE_PREFIX}__${EDIT_MODE_PREFIX}__${LOCATION_WIDGET_PREFIX}__zip-input`,
  PhoneInput: `${FEATURE_PREFIX}__${EDIT_MODE_PREFIX}__${LOCATION_WIDGET_PREFIX}__phone-input`,
  CategoryPrefix: `${FEATURE_PREFIX}__${EDIT_MODE_PREFIX}__${LOCATION_WIDGET_PREFIX}__category-prefix`,
  CarriedBrandsDropdown: `${FEATURE_PREFIX}__${EDIT_MODE_PREFIX}__${LOCATION_WIDGET_PREFIX}__carried-brands-dropdown`,
  ClienteleMaleSelector: `${FEATURE_PREFIX}__${EDIT_MODE_PREFIX}__${LOCATION_WIDGET_PREFIX}__clientele-male-selector`,
  ClienteleFemaleSelector: `${FEATURE_PREFIX}__${EDIT_MODE_PREFIX}__${LOCATION_WIDGET_PREFIX}__clientele-female-selector`,
  ClienteleAgeSelector: `${FEATURE_PREFIX}__${EDIT_MODE_PREFIX}__${LOCATION_WIDGET_PREFIX}__clientele-age-selector`,
  ClienteleDescriptionPrefix: `${FEATURE_PREFIX}__${EDIT_MODE_PREFIX}__${LOCATION_WIDGET_PREFIX}__clientele-description-prefix`,
  ClienteleInterestPrefix: `${FEATURE_PREFIX}__${EDIT_MODE_PREFIX}__${LOCATION_WIDGET_PREFIX}__clientele-interest-prefix`,
} as const
