import { Button } from '@joor/design-system'
import { useFlags } from '@joor/launchdarkly-react-client-sdk'
import { useSelector } from 'react-redux'
import { generatePath } from 'react-router-dom'
import styled from 'styled-components'

import {
  allowManagePayments,
  isAccountTypeRetailer,
  isLiteBrand,
} from 'store/currentUser/selectors'

import { EXTERNAL_PAYMENTS, ORDER_LIST } from 'routes/paths'

import { JOOR_PAY_LINK } from '../../constants/constants'
import { IPaymentsDropdownSelectors } from '../../navbarRevamp.types'
import DropdownMenuGroup from '../DropdownMenuGroup/DropdownMenuGroup'
import DropdownMenuTextLink from '../DropdownMenuTextLink/DropdownMenuTextLink'
import NavbarDropdown from '../NavbarDropdown/NavbarDropdown'
import Trigger from '../Trigger/Trigger'
import messages from 'features/Navbar/NavbarRevamp/constants/navbarRevamp.messages'
import { PaymentsDropdownIds } from 'features/Navbar/NavbarRevamp/navbarRevamp.ids'

const NavbarPaymentsDropdownContainer = styled.div`
  display: flex;
`
const ButtonContainer = styled.div`
  border-top: 1px solid var(--color-primary-400);
  padding-top: 16px;
`

const ButtonWrapper = styled.div`
  text-align: center;
`

const PaymentsMenuGroup = () => (
  <DropdownMenuGroup>
    <DropdownMenuTextLink
      to={`${generatePath(ORDER_LIST)}?tab=joorPayOrders`}
      message={messages.manageJoorPayOrders}
      id={PaymentsDropdownIds.PaymentsOptionsManageJoorPayOrders}
      testId={PaymentsDropdownIds.PaymentsOptionsManageJoorPayOrders}
    />
    <DropdownMenuTextLink
      to={generatePath(EXTERNAL_PAYMENTS)}
      message={messages.externalPayments}
      id={PaymentsDropdownIds.PaymentsOptionsExternalPayments}
      testId={PaymentsDropdownIds.PaymentsOptionsExternalPayments}
    />
  </DropdownMenuGroup>
)

const PromoMenu = () => (
  <DropdownMenuGroup>
    <DropdownMenuTextLink
      target="_blank"
      to={{ pathname: JOOR_PAY_LINK }}
      message={messages.paymentPromo}
      id={PaymentsDropdownIds.PaymentsOptionsJOORPayPromo}
      testId={PaymentsDropdownIds.PaymentsOptionsJOORPayPromo}
    />
    <ButtonContainer>
      <ButtonWrapper>
        <Button
          id={PaymentsDropdownIds.PaymentsOptionsJOORPayButton}
          onClick={() => {
            window.open(JOOR_PAY_LINK)
          }}
        >
          {messages.learnMore}
        </Button>
      </ButtonWrapper>
    </ButtonContainer>
  </DropdownMenuGroup>
)

const NavbarPaymentsDropdown = () => {
  const { joorPayUseReachExternalPayments } = useFlags()
  const {
    canManagePayments,
    isRetailerAccount,
    isLiteBrandAccount,
  } = useSelector<object, IPaymentsDropdownSelectors>((state) => ({
    canManagePayments: allowManagePayments(state),
    isRetailerAccount: isAccountTypeRetailer(state),
    isLiteBrandAccount: isLiteBrand(state),
  }))
  if (isRetailerAccount) {
    return null
  }

  const canSeePayments =
    joorPayUseReachExternalPayments && canManagePayments && !isLiteBrandAccount

  return (
    <NavbarPaymentsDropdownContainer>
      <NavbarDropdown
        trigger={
          <Trigger
            label={messages.payments}
            id={PaymentsDropdownIds.PaymentTrigger}
          />
        }
      >
        {canSeePayments ? <PaymentsMenuGroup /> : <PromoMenu />}
      </NavbarDropdown>
    </NavbarPaymentsDropdownContainer>
  )
}

export default NavbarPaymentsDropdown
