import { useState } from 'react'

import styled from 'styled-components/macro'

import { useStorageLanguages } from 'utils/sdks/localize'
import { switchLanguage as switchLanguageZendesk } from 'utils/sdks/zendesk'

export const LanguageSelectorTestIds = {
  LanguageSelector: 'LanguageSelector',
} as const

export const LanguageSelector = ({
  testId = LanguageSelectorTestIds.LanguageSelector,
}: {
  testId?: string
}) => {
  const { language, languages, switchStorageLanguage } = useStorageLanguages()
  const [isHovered, setIsHovered] = useState(false)

  const handleSelectLanguage = (code: string) => {
    setIsHovered(false)
    switchStorageLanguage(code)
    switchLanguageZendesk(code)
  }

  return (
    <HoverTrigger
      data-testid={testId}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {language.name}
      <LanguageOptions className={isHovered ? 'visible' : 'hidden'}>
        {languages.map((lang) => (
          <div
            key={lang.code}
            className="item"
            data-testid={`${testId}__${lang.code}`}
            onClick={() => {
              handleSelectLanguage(lang.code)
            }}
          >
            {lang.name}
          </div>
        ))}
      </LanguageOptions>
    </HoverTrigger>
  )
}

const HoverTrigger = styled.div`
  position: relative;
  height: 42px;
  display: flex;
  align-items: center;

  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 1.4px;

  cursor: pointer;

  &:hover {
    color: var(--color-secondary-800);
  }
`

const LanguageOptions = styled.div`
  position: absolute;
  top: 40px;
  right: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 9999;
  width: 200px;

  &.hidden {
    display: none;
  }
  &.visible {
    display: flex;
  }

  .item {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    color: var(--color-primary-800);
    cursor: pointer;

    &:hover {
      color: var(--color-secondary-800);
    }
  }
`
