import { useMutation } from '@apollo/client'

import { VisitRetailerProfileDocument } from '__generated__/atlas-types'

import { atlasClient } from 'graphql/client'
import { atlas } from 'graphql/tags'

atlas`#graphql
mutation VisitRetailerProfile($input: VisitRetailerProfileInput!) {
  visitRetailerProfile(input: $input) {
    success
  }
}
`

export const useVisitToRetailerProfile = (retailerId: string) => {
  const [visit] = useMutation(VisitRetailerProfileDocument, {
    client: atlasClient,
  })
  const recordVisit = () => {
    visit({
      variables: { input: { retailerId } },
    })
  }

  return { recordVisit }
}
