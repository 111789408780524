import classNames from 'classnames/bind'
import PropTypes from 'prop-types'

import Info from 'components/Core/Info/Info'
import Tooltip, { YELLOW } from 'components/Core/Tooltip/Tooltip'

import styles from './SubHeader.less'

const SubHeader = ({ children, className }) => (
  <div className={classNames(className, styles.SubHeader)}>{children}</div>
)

const Title = ({ children, tooltip, id }) => (
  <div className={styles.Title}>
    <h2 className={classNames({ [styles.hasTooltip]: tooltip })} id={id || ''}>
      {children}
    </h2>
    {tooltip && (
      <Info content={tooltip.content} tooltipClassName={tooltip.className} />
    )}
  </div>
)

const Subtitle = ({ children }) => (
  <div className={styles.Subtitle}>{children}</div>
)

const Group = ({ children }) => (
  <div className={styles.LabelGroup}>{children}</div>
)

const Label = ({ children, tooltipOptions }) => {
  const tooltip = tooltipOptions && (
    <Tooltip
      hoverable
      position="top center"
      content={tooltipOptions.content}
      trigger={children}
      color={YELLOW}
      className={tooltipOptions.className}
    />
  )

  return tooltip || children
}

SubHeader.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}

SubHeader.defaultProps = {
  className: '',
  children: null,
}

Title.propTypes = {
  children: PropTypes.node,
  tooltip: PropTypes.object,
}

Title.defaultProps = {
  children: null,
  tooltip: null,
}

Subtitle.propTypes = {
  children: PropTypes.node,
}

Subtitle.defaultProps = {
  children: null,
}

Label.propTypes = {
  children: PropTypes.node,
  tooltipOptions: PropTypes.object,
}

Label.defaultProps = {
  children: null,
  tooltipOptions: null,
}

Group.propTypes = {
  children: PropTypes.node,
}

Group.defaultProps = {
  children: null,
}

SubHeader.Title = Title
SubHeader.Subtitle = Subtitle
SubHeader.Label = Label
SubHeader.Group = Group

export default SubHeader
