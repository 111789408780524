import { Paragraph, Text } from '@joor/design-system'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { formatDate } from 'utils/formatters'
import { fromGlobalId } from 'utils/transformations/graphql'

import { getDateFormat } from 'store/currentUser/selectors'

import { INBOX } from 'routes/paths'

import { ViewModeIds } from 'features/LeadGen/RetailerProfile/retailerProfilePage.ids'

const StyledLink = styled.a`
  cursor: pointer;
  color: var(--color-secondary-800, #008bad) !important;

  &:hover {
    color: var(--color-secondary-600) !important;
  }
`

export type SubmissionSent = {
  rep: string
  date: string
  messageId: string
}

type SubmissionLinkProps = {
  submissionSent: SubmissionSent
}

export function SubmissionLink({
  submissionSent: { rep, date, messageId },
}: SubmissionLinkProps) {
  const userDateFormat = useSelector(getDateFormat) as string
  const history = useHistory()
  const { id } = fromGlobalId(messageId)

  const submissionUrl = `${INBOX}/view/${id}`

  const repName = rep ? ` by ${rep}` : ''

  return (
    <Paragraph type="3" color="primary-500" textAlign="center">
      <Text
        as="span"
        color="secondary-800"
        letterSpacing="tighter"
        fontSize="small"
        fontWeight="bold"
      >
        <StyledLink
          id={`${ViewModeIds.ContactLink}-${id}`}
          onClick={() => history.push(submissionUrl)}
        >
          Contacted
        </StyledLink>
      </Text>
      {`${repName} on ${formatDate(date, userDateFormat)}`}
    </Paragraph>
  )
}
