import { useEffect } from 'react'

import { Icon, SmallGammaBadge } from '@joor/design-system'
import { useDispatch, useSelector } from 'react-redux'

import { formatNumber } from 'utils/formatters'

import { isAccountTypeBrand, isProRetailer } from 'store/currentUser/selectors'
import {
  setCartInfo,
  setShouldDisableCart,
  setShouldUpdateCart,
} from 'store/shop/actions'
import {
  shouldDisableCart as shouldDisableCartSelector,
  shouldUpdateCart as shouldUpdateCartSelector,
} from 'store/shop/selectors'

import { ORDERS_CART } from 'routes/paths'

import DropdownMenuGroup from '../DropdownMenuGroup/DropdownMenuGroup'
import NavbarDropdown from '../NavbarDropdown/NavbarDropdown'
import Trigger from '../Trigger/Trigger'
import {
  CartDropdownContainer,
  CartMenuIconContainer,
  CartMenuLabel,
  QuantityBadge,
  StyledCartIconLink,
} from './CartDropdown.ui'
import { AccountType, useCartData } from './cartDropdown.queries'
import BrandCart from './components/BrandCart/BrandCart'
import messages from 'features/Navbar/NavbarRevamp/constants/navbarRevamp.messages'
import { CartDropdownIds } from 'features/Navbar/NavbarRevamp/navbarRevamp.ids'
import {
  INavbarCartDropdown,
  INavbarCartSelectors,
} from 'features/Navbar/NavbarRevamp/navbarRevamp.types'

interface ICustomCartTrigger {
  unitsInCart: number
  isInsideDropdown: boolean
}

const CartMenuIcon = ({
  unitsInCart,
  isInsideDropdown,
}: ICustomCartTrigger) => (
  <>
    <CartMenuIconContainer isInsideDropdown={isInsideDropdown}>
      <Icon iconName="cart" variant="DEFAULT" type="white" plain />
      {unitsInCart > 0 && (
        <QuantityBadge>
          <SmallGammaBadge
            id={CartDropdownIds.CartBadge}
            value={formatNumber(unitsInCart)}
          />
        </QuantityBadge>
      )}
    </CartMenuIconContainer>
    {isInsideDropdown && <CartMenuLabel>{messages.cart}</CartMenuLabel>}
  </>
)
const CustomCartTrigger = ({
  unitsInCart,
  isInsideDropdown,
}: ICustomCartTrigger) => (
  <Trigger
    isInsideDropdown={isInsideDropdown}
    label={
      <CartMenuIcon
        unitsInCart={unitsInCart}
        isInsideDropdown={isInsideDropdown}
      />
    }
    id={CartDropdownIds.CartTrigger}
  />
)

const CartDropdown = ({
  unitsInCart,
  isInsideDropdown,
  accountsWithOrders,
  isCartLoading,
}: {
  unitsInCart: number
  isInsideDropdown: boolean
  accountsWithOrders: AccountType[]
  isCartLoading: boolean
}) => (
  <NavbarDropdown
    testId={CartDropdownIds.CartTrigger}
    trigger={
      <CustomCartTrigger
        unitsInCart={unitsInCart}
        isInsideDropdown={isInsideDropdown}
      />
    }
    rightPosition
    isInsideDropdown={isInsideDropdown}
  >
    <DropdownMenuGroup>
      <BrandCart
        accountsWithOrders={accountsWithOrders}
        isCartLoading={isCartLoading}
      />
    </DropdownMenuGroup>
  </NavbarDropdown>
)
const NavbarCartDropdown = ({
  isInsideDropdown = false,
}: INavbarCartDropdown) => {
  const dispatch = useDispatch()
  const {
    shouldUpdateCart,
    isBrandAccount,
    isProRetailerAccount,
    shouldDisableCart,
  } = useSelector<object, INavbarCartSelectors>((state) => ({
    shouldUpdateCart: shouldUpdateCartSelector(state),
    isBrandAccount: isAccountTypeBrand(state),
    isProRetailerAccount: isProRetailer(state),
    shouldDisableCart: shouldDisableCartSelector(state),
  }))
  const {
    accountsWithOrders,
    cartLoading,
    refetchOrdersInCart,
    unitsInCart,
  } = useCartData()

  useEffect(() => {
    if (shouldUpdateCart) {
      refetchOrdersInCart()
      dispatch(setShouldUpdateCart(false))
      dispatch(setShouldDisableCart(false))
    }
  }, [shouldUpdateCart])

  useEffect(() => {
    if (!cartLoading) {
      dispatch(setCartInfo(accountsWithOrders))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartLoading])

  if (isProRetailerAccount) {
    return null
  }
  return (
    <CartDropdownContainer
      isInsideDropdown={isInsideDropdown}
      data-testid={CartDropdownIds.CartContainer}
    >
      {isBrandAccount ? (
        <CartDropdown
          isInsideDropdown={isInsideDropdown}
          unitsInCart={unitsInCart}
          accountsWithOrders={accountsWithOrders}
          isCartLoading={shouldDisableCart || shouldUpdateCart || cartLoading}
        />
      ) : (
        <StyledCartIconLink
          to={ORDERS_CART}
          id={CartDropdownIds.CartLink}
          data-testid={CartDropdownIds.CartLink}
        >
          <CartMenuIcon
            unitsInCart={unitsInCart}
            isInsideDropdown={isInsideDropdown}
          />
        </StyledCartIconLink>
      )}
    </CartDropdownContainer>
  )
}

export default NavbarCartDropdown
