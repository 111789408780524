import { SecondaryPill } from '@joor/design-system'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { IDropdownMenuTextLink } from 'features/Navbar/NavbarRevamp/navbarRevamp.types'

const WrappedLink = styled(Link)`
  cursor: pointer;
  text-decoration-line: none;
  margin: auto 0;
  display: flex;
  line-height: 16px;
`

const MenuText = styled.div`
  font-size: var(--size-font-small);
  font-weight: var(--font-weight-medium);
  font-family: var(--font-family-alpha);
  letter-spacing: 0.6px;
  line-height: 16px;
  padding: 4px 9px;
  width: 100%;

  &:hover {
    border: 1px solid var(--color-primary-400);
    border-radius: 4px;
    padding: 3px 8px;
  }
`

const MenuTextMessage = styled.span`
  color: var(--color-secondary-800);
`

const MenuPill = styled.span`
  margin-left: 8px;
`

const DropdownMenuTextLink = ({
  to,
  className,
  isNew = false,
  message,
  target,
  onClick,
  testId,
  id,
}: IDropdownMenuTextLink) => (
  <WrappedLink
    to={to}
    target={target}
    data-testid={testId}
    id={id}
    className="menuTextLink"
  >
    <MenuText
      className={className}
      onClick={(event) => onClick && onClick(event)}
    >
      <MenuTextMessage>{message}</MenuTextMessage>
      {/* Update styles to match the current New Pill UI */}
      {isNew && (
        <MenuPill>
          <SecondaryPill>New!</SecondaryPill>
        </MenuPill>
      )}
    </MenuText>
  </WrappedLink>
)

export default DropdownMenuTextLink
