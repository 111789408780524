import { ReactElement } from 'react'

import { Button, Caption, Icon, Paragraph, Subtitle } from '@joor/design-system'
import { useHistory } from 'react-router-dom'
import styled, { css } from 'styled-components'

import {
  SubmissionLink,
  SubmissionSent,
} from '../../RetailerProfileViewMode/components/SubmissionLink/SubmissionLink'
import { ViewModeIds } from 'features/LeadGen/RetailerProfile/retailerProfilePage.ids'

const CardContainer = styled.div`
  width: 307px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 5px;
  border: 1px solid var(--Primary-200, #cdd4da);
  border-radius: 5px;
  background: #fff;
  &.disabled {
    border: 1px solid rgba(205, 212, 218, 0.5);
  }
`

const CardContent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const CircleContainer = styled.div`
  width: 68px;
  height: 68px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-primary-100);
  border-radius: 50%;
`
const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  justify-content: center;
`

const StatusContainer = styled.div`
  display: inline-grid;
  opacity: 1;
  &.disabled {
    opacity: 0.5;
  }
`
const Image = styled.img`
  width: 68px;
  height: 68px;
  border-radius: 50%;
  border: 1px solid var(--color-primary-100);
  border-style: solid !important;
  object-fit: cover;
`
const Ellipsis = css`
  white-space: nowrap !important;
  text-overflow: ellipsis;
  overflow: hidden;
`
const StyledName = styled(Subtitle)`
  ${Ellipsis}
`
const StyledJobTitle = styled(Paragraph)`
  ${Ellipsis};
`
const StyledPhone = styled(Caption)`
  margin-top: 4px !important;
`

const ProfileImageFallback = () => {
  return (
    <CircleContainer>
      <Icon iconName="person" color="var(--color-primary-500)" plain />
    </CircleContainer>
  )
}

type CardProps = {
  name: string
  jobTitle?: string | null
  phone?: string | null
  profileImageUrl?: string | null
  disabled?: boolean
  contactUrl?: string
  submissionSent?: SubmissionSent
  isRetailer: boolean
  id?: string
  children?: ReactElement
}

const BuyerCard = ({
  name,
  jobTitle,
  phone,
  profileImageUrl,
  disabled = false,
  contactUrl,
  submissionSent,
  isRetailer,
  id,
  children,
}: CardProps) => {
  const history = useHistory()

  const shouldShowContactButton = contactUrl && !submissionSent && !isRetailer
  const shouldShowContactInfo = submissionSent && !isRetailer

  return (
    <CardContainer className={disabled ? 'disabled' : ''} data-testid={id}>
      <CardContent>
        <StatusContainer className={disabled ? 'disabled' : ''}>
          {profileImageUrl ? (
            <Image src={profileImageUrl} />
          ) : (
            <ProfileImageFallback />
          )}
        </StatusContainer>

        <InfoContainer>
          <StatusContainer className={disabled ? 'disabled' : ''}>
            <StyledName className="notranslate" type="3">
              {name}
            </StyledName>
            {jobTitle && (
              <StyledJobTitle
                className="notranslate"
                type="3"
                breakLines={false}
              >
                {jobTitle}
              </StyledJobTitle>
            )}
            {phone && (
              <StyledPhone className="notranslate" type="3" color="primary-500">
                {phone}
              </StyledPhone>
            )}
          </StatusContainer>
          {children}
        </InfoContainer>
      </CardContent>
      {shouldShowContactButton && (
        <Button
          id={`${ViewModeIds.ContactButton}-${id}`}
          onClick={() => history.push(contactUrl)}
        >
          Contact
        </Button>
      )}
      {shouldShowContactInfo && (
        <SubmissionLink submissionSent={submissionSent} />
      )}
    </CardContainer>
  )
}

export { BuyerCard }
