import get from 'lodash/get'
import { generatePath } from 'react-router-dom'

import { fromGlobalId } from 'utils/transformations/graphql'

import { ReactComponent as AllYearBadge } from 'assets/images/PassportImages/all_year-badge.svg'
import { ReactComponent as ComingSoonBadge } from 'assets/images/PassportImages/coming-soon-badge.svg'
import { ReactComponent as EndsSoonBadge } from 'assets/images/PassportImages/ends_soon-badge.svg'
import { ReactComponent as LiveBadge } from 'assets/images/PassportImages/live-badge.svg'
import { ReactComponent as RefreshedBadge } from 'assets/images/PassportImages/refreshed-badge.svg'

import {
  PASSPORT_LANDING,
  PUBLIC_RETAILER_PROFILE,
  PUBLIC_STOREFRONT,
  RETAILER_PROFILE,
  STOREFRONT,
} from 'routes/paths'

export const PRIVATE_LOC_SEARCH = '?private'

export const handleEventRedirect = (eventCF, history) => {
  history.push(`${PASSPORT_LANDING}/${eventCF.slug}`)
  window.scrollTo(0, 0)
  return false
}

export const handlePrivateEventRedirect = (slug) => {
  window.open(`${PASSPORT_LANDING}/${slug}${PRIVATE_LOC_SEARCH}=true`, '_blank')
}

export const getListOfFavoritedAccountsOrNull = (favoritesData) => {
  const data = get(favoritesData, 'favoritedBrandsByRetailAccountUser', null)
  return data != null
    ? data.map((account) => fromGlobalId(account.id).id)
    : null
}

export const getListOfFavorites = (favoritesData) => {
  const data = get(favoritesData, 'favoritedBrandsByRetailAccountUser', [])
  return data.map((i) => ({
    id: fromGlobalId(i.id).id,
    brandId: fromGlobalId(i.id).id,
    name: i.profileName,
    slug: i.tradeShowEventSlug,
    mainImage: i.mainImage,
  }))
}

/**
 * Gets the correct brand profile URL for trade shows.
 * See https://app.clubhouse.io/joor/story/87978/redirect-r-to-ra-profile-if-logged-in for details.
 *
 * @param {Object} brand
 * @param {boolean} isLoggedIn
 *
 * @returns {string} brand profile URL to redirect to within a react-router-dom Link component
 */
export const getBrandProfileUrl = (brand, isLoggedIn) => {
  const private_brand_profile_url = generatePath(STOREFRONT, {
    accountId: brand.brandId ?? fromGlobalId(brand.id)?.id,
  })
  const public_brand_profile_url = generatePath(PUBLIC_STOREFRONT, {
    accountId: brand.brandId ?? fromGlobalId(brand.id)?.id,
  })

  return isLoggedIn ? private_brand_profile_url : public_brand_profile_url
}

export const getRetailerProfileUrl = (retailerId, isLoggedIn) => {
  return isLoggedIn
    ? generatePath(RETAILER_PROFILE, {
        accountId: fromGlobalId(retailerId).id,
      })
    : generatePath(PUBLIC_RETAILER_PROFILE, {
        accountId: fromGlobalId(retailerId).id,
      })
}

/**
 * Get the text for the badge.
 *
 * @param {string} the string representing the brand's badge type
 */

export const getBadgeText = (badge) => {
  switch (badge) {
    case 'coming-soon':
      return 'Coming Soon'
    case 'ending-soon':
      return 'Ending Soon'
    case 'refreshed':
      return badge
    case 'all-year':
      return 'All year'
    case 'live-now':
      return 'Live Now'
    default:
      return false
  }
}

/**
 * Get the svg image for the badge.
 *
 * @param {string} the string representing the brand's badge type
 */

export const getBadgeSVG = (badge) => {
  switch (badge) {
    case 'coming-soon':
      return <ComingSoonBadge />
    case 'ending-soon':
      return <EndsSoonBadge />
    case 'refreshed':
      return <RefreshedBadge />
    case 'all-year':
      return <AllYearBadge />
    case 'live-now':
      return <LiveBadge />
    default:
      return false
  }
}
